import React from 'react';
import { useConnectEntityModal } from 'services/StorageAdapter';
import { Avatar, Button, FormField } from 'shared/ui/index';
import { Chip, Grid, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LinkIcon from '@mui/icons-material/Link';
import { CancelRounded } from '@mui/icons-material';
import { FormikProps, FormikValues } from 'formik';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { Nullable } from 'shared/types';
import { isItemsArray, isMultiple } from 'shared/helpers/typeGuards';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ConnectFieldProps = {
    field: string;
    setValue: FormikProps<FormikValues>['setFieldValue'];
    value: Nullable<ConnectedObjectType | ConnectedObjectType[]>;
    items: Nullable<ConnectedObjectType | ConnectedObjectType[]>;
    type:
        | 'fk'
        | 'm2m'
        | 'fk_employee'
        | 'm2m_employee'
        | 'fk_department'
        | 'm2m_department'
        | 'uuid'
        | 'uuid_many';
    label: string;
    required?: boolean;
    code: string;
    isEdit?: boolean;
    error?: string;
    modalTitle?: string;
};

const ConnectField = ({
    field,
    value,
    setValue,
    type,
    label,
    code,
    required,
    isEdit,
    items,
    error,
    modalTitle,
}: ConnectFieldProps) => {
    const { t } = useTranslation();
    const { open: openModal } = useConnectEntityModal();

    const handleConnectClick = (code: string, field: string, type: string) => {
        openModal({
            code: code,
            handleSelect(entity): void {
                setValue(field, entity);
            },
            selected: value || null,
            type: type,
            modalTitle,
        });
    };

    const navigate = useNavigate();

    const handleClickTask = (id: string) => {
        if (code === 'tasks') {
            navigate(`./?task_id=${id}`);
        }
    };
    return (
        <FormField label={label} required={required} error={!!error} helperText={error}>
            <Grid container flexDirection={'column'} alignItems={'flex-start'} gap={'12px'}>
                {value && !isMultiple(value) && (
                    <>
                        {code === 'tasks' && !isEdit ? (
                            <NavLink to={`./?task_id=${value.id}`}>
                                <Chip
                                    onClick={() => {
                                        handleClickTask(value.id);
                                    }}
                                    variant={'outlined'}
                                    icon={
                                        <Avatar variant="rounded" size={'small'}>
                                            <Typography fontSize={'inherit'} color={'white'}>
                                                AB
                                            </Typography>
                                        </Avatar>
                                    }
                                    onDelete={
                                        isEdit
                                            ? () => {
                                                  setValue(field, null);
                                              }
                                            : undefined
                                    }
                                    deleteIcon={isEdit ? <CancelIcon /> : undefined}
                                    label={
                                        value.title ||
                                        value.fullname ||
                                        value.name ||
                                        `${value.user?.first_name} ${value.user?.last_name}`
                                    }
                                />
                            </NavLink>
                        ) : (
                            <Chip
                                onClick={() => {
                                    handleClickTask(value.id);
                                }}
                                variant={'outlined'}
                                icon={
                                    <Avatar variant="rounded" size={'small'}>
                                        <Typography fontSize={'inherit'} color={'white'}>
                                            AB
                                        </Typography>
                                    </Avatar>
                                }
                                onDelete={
                                    isEdit
                                        ? () => {
                                              setValue(field, null);
                                          }
                                        : undefined
                                }
                                deleteIcon={isEdit ? <CancelIcon /> : undefined}
                                label={
                                    value.title ||
                                    value.fullname ||
                                    value.name ||
                                    `${value.user?.first_name} ${value.user?.last_name}`
                                }
                            />
                        )}
                    </>
                )}
                {value &&
                    isMultiple(value) &&
                    items &&
                    isItemsArray(items) &&
                    items.map(({ id, name, fullname, first_name, last_name, title }) => {
                        return (
                            <>
                                {code === 'tasks' && !isEdit ? (
                                    <NavLink
                                        style={{
                                            pointerEvents: code === 'tasks' ? 'auto' : 'none',
                                        }}
                                        key={id}
                                        to={code === 'tasks' ? `./?task_id=${id}` : '.'}
                                    >
                                        <Chip
                                            onClick={() => {
                                                handleClickTask(id);
                                            }}
                                            variant={'outlined'}
                                            deleteIcon={isEdit ? <CancelIcon /> : undefined}
                                            onDelete={
                                                isEdit
                                                    ? () => {
                                                          setValue(
                                                              field,
                                                              value.filter((v) => v.id !== id)
                                                          );
                                                      }
                                                    : undefined
                                            }
                                            icon={
                                                <Avatar variant="rounded" size={'small'}>
                                                    <Typography fontSize={'inherit'} color={'white'}>
                                                        AB
                                                    </Typography>
                                                </Avatar>
                                            }
                                            label={title || fullname || name || `${first_name} ${last_name}`}
                                        />
                                    </NavLink>
                                ) : (
                                    <Chip
                                        onClick={() => {
                                            handleClickTask(id);
                                        }}
                                        variant={'outlined'}
                                        deleteIcon={isEdit ? <CancelIcon /> : undefined}
                                        onDelete={
                                            isEdit
                                                ? () => {
                                                      setValue(
                                                          field,
                                                          value.filter((v) => v.id !== id)
                                                      );
                                                  }
                                                : undefined
                                        }
                                        icon={
                                            <Avatar variant="rounded" size={'small'}>
                                                <Typography fontSize={'inherit'} color={'white'}>
                                                    AB
                                                </Typography>
                                            </Avatar>
                                        }
                                        label={title || fullname || name || `${first_name} ${last_name}`}
                                    />
                                )}
                            </>
                        );
                    })}
                {isEdit && (
                    <Grid container flexWrap={'nowrap'} gap={'8px'}>
                        <Button
                            onClick={() => {
                                handleConnectClick(code, field, type);
                            }}
                            startIcon={
                                (value && isMultiple(value) && value?.length) || value ? (
                                    <ChangeCircleIcon />
                                ) : (
                                    <LinkIcon />
                                )
                            }
                        >
                            {(value && isMultiple(value) && value?.length) || value ? t('change') : t('add')}
                        </Button>
                        <Button
                            onClick={() => {
                                setValue(field, value && isMultiple(value) ? [] : null);
                            }}
                            startIcon={<CancelRounded />}
                            color={'error'}
                            disabled={Boolean((value && isMultiple(value) && value?.length === 0) || !value)}
                        >
                            {t('clear')}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </FormField>
    );
};

export default ConnectField;
