import React from 'react';
import { Button, Input, Label, Modal } from '../../../../shared/ui';
import { Grid } from '@mui/material';
import ConnectField from '../../../../shared/ui/ConnectField/ConnectField';
import { useFormik } from 'formik';
import { useNotification } from '../../../../services/StorageAdapter';
import { ICreateDocumentBody } from '../../../../domain/documents';

interface IProps {
    open: boolean;
    onClose: () => void;
    createDocument: (body: ICreateDocumentBody) => Promise<any>;
    isLoadingCreated: boolean;
}

const AddTypeDocumentModal = (props: IProps) => {
    const { open, onClose, createDocument, isLoadingCreated } = props;
    const { notify } = useNotification();
    const { values, errors, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            name: '',
            entity: null,
        },
        onSubmit: (values: any) => {
            createDocument({
                name: values.name,
                entities: values.entity.map((i: { id: string }) => i.id),
            }).then(() => {
                notify({
                    message: 'Успешно добавлен',
                });
                onClose();
            });
        },
    });
    return (
        <Modal title="Добавить тип документа" width={700} open={open} onClose={onClose}>
            <Grid container sx={{ padding: '30px' }} spacing={3}>
                <Grid item md={12}>
                    <Label label="Тип документа" />
                    <Input
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        fullWidth
                        placeholder="Соглашение..."
                    />
                </Grid>
                <Grid item display="flex">
                    <ConnectField
                        error={errors['entity'] as string}
                        items={values['entity']}
                        label="Сущность с доступом"
                        required
                        setValue={setFieldValue}
                        value={values['entity']}
                        field={'entity'}
                        modalTitle="Добавить сущность с доступом"
                        type={'m2m'}
                        code={'meta-entities'}
                        isEdit={true}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" sx={{ paddingRight: '30px' }}>
                <Button
                    loading={isLoadingCreated}
                    sx={{ marginRight: '20px' }}
                    variant="contained"
                    disabled={Boolean(!(values.name && values.entity))}
                    onClick={() => handleSubmit()}
                >
                    Добавить
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Отменить
                </Button>
            </Grid>
        </Modal>
    );
};

export default AddTypeDocumentModal;
