import React, { useEffect } from 'react';
import { EnhancedTable, Loader } from '../../../shared/ui';
import { documentTypesHeaders } from '../consts';
import EmptyTable from '../../../shared/ui/EnhancedTable/EmptyTable';
import { Typography } from '@mui/material';
import AddTypeDocumentModal from './Modals/AddTypeDocumentModal';
import { useDocumentsStorage } from '../../../services/StorageAdapter';

const DocumentTypes = ({ toggleModal, open }: { toggleModal: () => void; open: boolean }) => {
    const { fetchDocuments, documents, createDocument, isLoadingCreated, updateTypeDocument, loadingData } =
        useDocumentsStorage();

    useEffect(() => {
        fetchDocuments({ limit: 10, offset: 0 });
    }, []);
    return (
        <>
            {loadingData ? (
                <Loader sx={{ height: '100%' }} />
            ) : documents.results.length > 0 ? (
                <EnhancedTable
                    onUpdate={(params) => {
                        fetchDocuments({ limit: params.limit, offset: params.offset });
                    }}
                    header={documentTypesHeaders}
                    data={documents.results}
                    count={documents.count}
                    limit={documents?.params?.limit || 0}
                    offset={documents?.params?.offset || 0}
                    bottom_menu={[
                        {
                            title: 'Аннулировать',
                            func: (item: string[]) => {
                                updateTypeDocument(item);
                            },
                            type: 'delete',
                        },
                    ]}
                />
            ) : (
                <EmptyTable>
                    <>
                        <Typography variant="body1" sx={{ fontWeight: '500', fontSize: '20px' }} mb={1.5}>
                            Тип документов не созданы
                        </Typography>
                        <Typography variant="body2">
                            После создания типа документа перейдите на вкладку <br /> «Шаблоны» вверху и
                            создайте шаблон документа.
                        </Typography>
                    </>
                </EmptyTable>
            )}
            {open && (
                <AddTypeDocumentModal
                    createDocument={createDocument}
                    isLoadingCreated={isLoadingCreated}
                    open={open}
                    onClose={toggleModal}
                />
            )}
        </>
    );
};

export default DocumentTypes;
