import React from 'react';
import { Grid, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';

const DepartmentsCount = ({ id = '', value }: { id?: string; value: string }) => {
    const navigate = useNavigate();
    return (
        <Grid display="flex" alignItems="center">
            <IconButton onClick={() => navigate(`/distribution?view=${id}`)}>
                <InfoOutlinedIcon />
            </IconButton>
            <div>{value}</div>
        </Grid>
    );
};

export default DepartmentsCount;
