import {
    IDataApiService,
    IFunnelsApiService,
    IFunnelViewsApiService,
    IMetaApiService,
    IOrganizationApiService,
    IUserApiService,
    IRoleApiService,
    IDepartmentApiService,
    IWSApiService,
    IFieldApiService,
    IFieldsApiService,
    IDocumentsApiService,
} from 'application/ports/Api';
import { userService } from 'shared/api/user.service';
import { roleService } from 'shared/api/role.service';
import { IEntityApiService } from 'application/ports/Api';
import { metaService } from 'shared/api/meta.service';
import { funnelsService } from 'shared/api/funnels.service';
import { dataService } from 'shared/api/data.service';
import { funnelViewsService } from 'shared/api/funnels-views.service';
import { entityService } from 'shared/api/entity';
import { departmentService } from 'shared/api/department.service';
import { wsService } from 'shared/api/ws.service';
import { organizationService } from 'shared/api/organization.service';
import { Field } from 'domain/field';
import { fieldsService } from 'shared/api/fields.service';
import { fieldService } from 'shared/api/field.service';
import { permissionsService } from 'shared/api/permissions.service';
import { documentsService } from 'shared/api/documents.service';
import { distributionService } from '../shared/api/distribution.service';
export function departmentApiService(): IDepartmentApiService {
    return {
        getDepartments: departmentService.getDepartments,
        updateDepartment: departmentService.updateDepartment,
        createDepartment: departmentService.createDepartment,
        getDepartment: departmentService.getDepartment,
        deleteDepartment: departmentService.deleteDepartment,
        setRoles: departmentService.setRoles,
        setUsers: departmentService.setUsers,
        getDistributionDepartments: departmentService.getDistributionDepartments,
    };
}

export function documentsApiService(): IDocumentsApiService {
    return {
        getDocuments: documentsService.getDocuments,
        getTemplateDocuments: documentsService.getTemplatesDocuments,
        createDocument: documentsService.createDocument,
        updateTypeDocument: documentsService.updateTypeDocument,
        getNumerators: documentsService.getNumerators,
    };
}

export function distributionApiService() {
    return {
        getDistributions: distributionService.getDistributions,
    };
}

export function permissionsApiService(): any {
    return {
        getPermissions: permissionsService.getPermissions,
        deletePermission: permissionsService.deletePermission,
        updatePermission: permissionsService.updatePermission,
        createPermission: permissionsService.createPermission,
    };
}

export function roleApiService(): IRoleApiService {
    return {
        getRole: roleService.getRole,
        getRoles: roleService.getRoles,
        deleteRole: roleService.deleteRole,
        updateRole: roleService.updateRole,
        createRole: roleService.createRole,
    };
}

export function userApiService(): IUserApiService {
    return {
        getUser: userService.getUser,
        getUsers: userService.getUsers,
        deactivateUser: userService.deactivateUser,
        activateUser: userService.activateUser,
        updateUser: userService.updateUser,
        createUser: userService.createUser,
        resetPassword: userService.resetPassword,
        verifyPassword: userService.verifyPassword,
        getMe: userService.getMe,
        changePassword: userService.changePassword,
    };
}

export function entityApiService(): IEntityApiService {
    return {
        getEntities: entityService.getEntities,
        getEntity: entityService.getEntity,
        createEntity: entityService.createEntity,
        updateEntity: entityService.updateEntity,
        exportData: entityService.exportData,
    };
}
export function metaApiService(): IMetaApiService {
    return {
        getMeta: metaService.getMeta,
        createMeta: metaService.createMeta,
        updateMeta: metaService.updateMeta,
        getMetas: metaService.getMetas,
        deleteMeta: metaService.deleteMeta,
    };
}

export function funnelsApiService(): IFunnelsApiService {
    return {
        getFunnels: funnelsService.getFunnels,
        getFunnelsID: funnelsService.getFunnelsID,
        setFunnelsStagesID: funnelsService.setFunnelsStagesID,
        setFunnelsFunnelsID: funnelsService.setFunnelsFunnelsID,
        createFunnel: funnelsService.createFunnel,
        deleteFunnel: funnelsService.deleteFunnel,
        updateFunnel: funnelsService.updateFunnel,
        bulkUpdateFunnel: funnelsService.bulkUpdateFunnel,
        restoreFunnel: funnelsService.restoreFunnel,
        getStages: funnelsService.getStages,
        createStage: funnelsService.createStage,
        deleteStage: funnelsService.deleteStage,
        updateStage: funnelsService.updateStage,
        bulkUpdateStage: funnelsService.bulkUpdateStage,
    };
}

export function funnelViewsApiService(): IFunnelViewsApiService {
    return {
        setViewType: funnelViewsService.setViewType,
        updateFields: funnelViewsService.updateFields,
        getViewType: funnelViewsService.getViewType,
        createViewType: funnelViewsService.createViewType,
        getUserFunnelView: funnelViewsService.getUserFunnelView,
    };
}

export function fieldApiService(): IFieldApiService {
    const updateField = (field: Partial<Field>) => {
        return fieldsService.updateField(field.id!, field);
    };

    const updateFieldGroup = (field: Field, groupId: string) => {
        fieldsService.updateField(field.id, { groupId });
    };

    return {
        updateField,
        updateFieldGroup,
        bulkUpdateFields: fieldsService.bulkUpdateFields,
    };
}

export function dataApiService(): IDataApiService {
    return {
        getTaskData: dataService.getTaskData,
        getEntityData: dataService.getEntityData,
        updateEntitySettings: dataService.updateEntitySettings,
        getDataCodeID: dataService.getDataCodeID,
        deleteEntity: dataService.deleteEntity,
        restoreEntity: dataService.restoreEntity,
        removeObjectFromEntity: dataService.removeObjectFromEntity,
        bulkHardDelete: dataService.bulkHardDelete,
        bulkRestore: dataService.bulkRestore,
    };
}

export function fieldsApiService(): IFieldsApiService {
    return {
        getFields: fieldService.getFields,
        getFieldsGroups: fieldService.getFieldsGroups,
        createEntityField: fieldService.createEntityField,
        updateEntityGroupField: fieldService.updateEntityGroupField,
        deleteField: fieldService.deleteField,

        createFieldsGroups: fieldService.createFieldsGroups,
        deleteGroups: fieldService.deleteGroups,
        updateGroupFields: fieldService.updateGroupFields,
        getField: fieldService.getField,
        updateField: fieldService.updateField,
        updateMetaField: fieldService.updateMetaField,

        getMetaField: fieldService.getMetaField,
        getFieldsMeta: fieldService.getFieldsMeta,
        createMetaField: fieldService.createMetaField,
        bulkUpdateEntityGroups: fieldService.bulkUpdateEntityGroups,
        bindMetaFieldsToEntityGroup: fieldService.bindMetaFieldsToEntityGroup,
        deleteFieldFromMeta: fieldService.deleteFieldFromMeta,
        restoreFieldFromMeta: fieldService.restoreFieldFromMeta,
        bulkHardDeleteFieldFromMeta: fieldService.bulkHardDeleteFieldFromMeta,
        bulkRestoreFieldMeta: fieldService.bulkRestoreFieldMeta,
    };
}

export function wsApiService(): IWSApiService {
    return {
        connect: wsService.connect,
        list: wsService.list,
        file: wsService.file,
        messages: wsService.messages,
        redirect: wsService.redirect,
    };
}

export function organizationApiService(): IOrganizationApiService {
    return {
        getDepartmentEmployees: organizationService.getDepartmentEmployees,
        getDepartmentEmployee: organizationService.getDepartmentEmployee,
        setRole: organizationService.setRole,
        setPermissions: organizationService.setPermissions,
        unsetRole: organizationService.unsetRole,

        getFiltersUsers: organizationService.getFiltersUsers,
        getFilterUsers: organizationService.getFilterUsers,
        createFilterUsers: organizationService.createFilterUsers,
        updateFilterUsers: organizationService.updateFilterUsers,
        deleteFilterUsers: organizationService.deleteFilterUsers,
    };
}
