import { useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import {
    ButtonProps,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Button from '../Button/Button';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import uuid from 'react-uuid';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';
import { useTranslation } from 'react-i18next';

type ProgressCallbackType = (status: Omit<FileUploadStatus, 'file' | 'abortSignal' | 'controller'>) => void;

export type FileForUploadType = {
    file: File;
    id: string;
    abortSignal: AbortSignal;
};

type UploadProps = ButtonProps & {
    accept?: Accept;
    format?: string;
    uploadedFiles: (files: FileForUploadType[], progressCallback: ProgressCallbackType) => void;
    multiple?: boolean;
    withoutPreloader?: boolean;
};

export type FileUploadStatus = {
    id: string;
    file: File;
    abortSignal: AbortSignal;
    controller: AbortController;
    status: 'error' | 'pending' | 'uploading' | 'success';
    reason?: string;
};

const Upload = ({
    uploadedFiles,
    withoutPreloader,
    accept = {},
    format = '.PNG, .JPEG',
    multiple = true,
}: UploadProps) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<FileUploadStatus[]>([]);
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);
    const onDrop = (files: File[]) => {
        setFiles((prev) => {
            const controller = new AbortController();
            const forUploadFiles: FileUploadStatus[] = files.map((file) => {
                return {
                    abortSignal: controller.signal,
                    controller,
                    id: uuid(),
                    file,
                    status: 'pending',
                };
            });
            uploadedFiles(
                forUploadFiles.filter((f) => f.status !== 'error'),
                progressHandler
            );
            return [...prev, ...forUploadFiles];
        });
    };

    const progressHandler = (status: Omit<FileUploadStatus, 'file' | 'abortSignal' | 'controller'>) => {
        setFiles((prev) =>
            prev
                .map((file) => {
                    if (file.id === status.id) {
                        return {
                            ...file,
                            ...status,
                        };
                    }
                    return file;
                })
                .filter((file) => file.status !== 'success')
        );
    };

    const handleAbortUpload = (fileData: FileUploadStatus) => {
        fileData.controller.abort();
        setFiles((prev) => prev.filter((file) => file.id !== fileData.id));
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple,
        accept,
    });
    return (
        <Grid
            {...getRootProps()}
            container
            justifyContent="center"
            alignItems="center"
            py="24px"
            px={'24px'}
            gap={'12px'}
            sx={{
                background: '#FCFBFC',
                border: '1px dashed rgba(0, 0, 0, 0.16)',
                borderRadius: '8px',
            }}
        >
            <Grid container justifyContent="center" alignItems="center" gap={'12px'}>
                <FileDownloadRoundedIcon color="primary" />
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the files here ...</p>
                ) : (
                    <Grid sx={{ maxWidth: '300px' }}>
                        {!mobileQuery && (
                            <>
                                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>
                                    Перетащите файл в эту область.
                                </Typography>
                                <Typography
                                    component={'span'}
                                    sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}
                                >
                                    {'Или '}
                                </Typography>
                            </>
                        )}
                        <Button aria-label="upload picture" sx={{ p: 0 }}>
                            <input hidden type="file" />
                            {mobileQuery ? t('uploadFiles') : 'загрузите с компьютера'}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <FormHelperText>
                <Typography fontSize={'inherit'} textAlign={'center'}>
                    {t('uploadFileDesc', {
                        size: '10 МБ',
                        format,
                    })}
                </Typography>
            </FormHelperText>
            {files.some((f) => f.status !== 'success') && (
                <>
                    <Grid container>
                        <Divider variant={'fullWidth'} sx={{ width: '100%' }} />
                    </Grid>
                    <Grid sx={{ maxHeight: '240px', overflowY: 'auto' }} container alignItems={'center'}>
                        {files.map((fileData, index) => {
                            const { file, status, reason, id } = fileData;

                            const progressColor = status === 'pending' ? 'secondary' : 'primary';
                            return (
                                <Grid
                                    key={id}
                                    container
                                    flexWrap={'nowrap'}
                                    gap={'12px'}
                                    alignItems={'center'}
                                    px={'24px'}
                                >
                                    <Typography
                                        sx={{
                                            width: '115px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {index + 1}. {file.name}
                                    </Typography>
                                    {!withoutPreloader && (
                                        <Grid item flexGrow={1}>
                                            {status !== 'error' ? (
                                                <LinearProgress color={progressColor} />
                                            ) : (
                                                <Typography color={'#D32F2F'}>{reason}</Typography>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid container width={'auto'} flexWrap={'nowrap'}>
                                        {status === 'error' && (
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    uploadedFiles([fileData], progressHandler);
                                                }}
                                                sx={{ color: '#0000008A' }}
                                            >
                                                <RefreshRoundedIcon fontSize={'inherit'} />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleAbortUpload(fileData);
                                            }}
                                            sx={{ color: '#0000008A' }}
                                        >
                                            <CancelRoundedIcon fontSize={'inherit'} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid container>
                        <Divider variant={'fullWidth'} sx={{ width: '100%' }} />
                    </Grid>
                    <Grid container justifyContent={'space-between'}>
                        <Typography variant={'body2'} color={'#00000099'}>
                            {files.reduce(
                                (acc, fileData) => (fileData.status !== 'success' ? acc + 1 : acc),
                                0
                            )}{' '}
                            загрузок
                        </Typography>
                        <Button
                            onClick={() => files.forEach((fileData) => handleAbortUpload(fileData))}
                            startIcon={<CancelRoundedIcon />}
                            color={'error'}
                        >
                            Отменить все загрузки
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default Upload;
