import React, { useState } from 'react';
import { Modal, TabPanel } from '../../../../shared/ui';
import Details from './steps/Details';
import { Grid, Tab, Tabs } from '@mui/material';
import Settings from './steps/Settings';
import UploadComponent from './steps/Upload';
import { TParamsRequest } from '../../../../shared/api/types';
interface IProps {
    open: boolean;
    onClose: () => void;
    fetchFunnels: () => Promise<any>;
    fetchNumerators: () => Promise<any>;

    setSuccess: any;
}
export interface INumerator {
    created: string;
    current_number: number;
    id: string;
    is_active: boolean;
    name: string;
    number_template: string;
    fetchDocuments: (params: TParamsRequest) => Promise<any>;
}
const AddGenerateDocumentModal = (props: IProps) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [documentId, setDocumentId] = useState(null);
    const { onClose, setSuccess, open } = props;
    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
        return event;
    };
    const handleChangeStep = (name = 'next') => {
        if (name === 'prev') {
            setCurrentTab((prev) => prev - 1);
            return;
        }

        setCurrentTab((prev) => prev + 1);
    };
    return (
        <Modal
            title="Создание шаблона документа"
            width={700}
            open={open}
            onClose={() => {
                onClose();
            }}
        >
            <Grid width="100%" mt={3} sx={{ padding: '0 30px' }}>
                <Tabs
                    value={currentTab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {/*<Tab*/}
                    {/*    sx={{ textTransform: 'none' }}*/}
                    {/*    label={chatT('chatSettings.accessSettings.title')}*/}
                    {/*/>*/}
                    <Tab sx={{ textTransform: 'none' }} label={'Детали'} />
                    <Tab disabled={!documentId} sx={{ textTransform: 'none' }} label={'Настройка'} />
                    <Tab disabled={!documentId} sx={{ textTransform: 'none' }} label={'Загрузка документа'} />
                </Tabs>
            </Grid>
            <Grid width="100%">
                <TabPanel index={0} value={currentTab}>
                    <Details
                        {...props}
                        setDocumentId={setDocumentId}
                        documentId={documentId}
                        handleChangeStep={handleChangeStep}
                    />
                </TabPanel>
                <TabPanel index={1} value={currentTab}>
                    <Settings handleChangeStep={handleChangeStep} documentId={documentId} />
                </TabPanel>
                <TabPanel index={2} value={currentTab}>
                    <UploadComponent documentId={documentId} onClose={onClose} setSuccess={setSuccess} />
                </TabPanel>
            </Grid>
        </Modal>
    );
};

export default AddGenerateDocumentModal;
