import { createTheme, CssBaseline, GlobalStyles, PaletteMode, Snackbar, ThemeProvider } from '@mui/material';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Button, getDesignTokens } from 'shared/ui';
import { globalStyles } from 'shared/ui/theme';
import { useAuthStorage, useNotification } from 'services/StorageAdapter';
import './style.scss';
import { ConnectEntityModal } from 'widgets';
import dayjs from 'dayjs';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { WithChat } from './application/provider/WithChat';
import { CustomSnackbarProvider } from './application/provider/CustomSnackbarProvider';
import { useNotificationTitle } from './widgets/NotificationBar';
import { AppContainer } from './application/provider/AppContainer';
import { useTitle } from 'shared/hooks/useTitle';
import useAutoLogout from './shared/hooks/useAutoLogout';
import AutoLogoutModal from './widgets/AutoLogoutModal';
import Logout from './shared/helpers/Logout';

dayjs.locale('ru');

interface IColorModeContext {
    toggleColorMode: () => void;
    currentMode: 'light' | 'dark';
}

export const ColorModeContext = createContext<IColorModeContext>({
    toggleColorMode: () => {},
    currentMode: 'light',
});

const App = () => {
    const { notifications } = useNotification();
    const { updateToken } = useAuthStorage();
    const [mode, setMode] = useState<PaletteMode>(
        () => (localStorage.getItem('theme') as PaletteMode) || 'light'
    );
    const [openAutoLogout, setOpenAutoLogout] = useState(false);
    useTitle('Эксперт Плюс');
    useNotificationTitle('Эксперт Плюс');
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => {
                    const newMode = prevMode === 'light' ? 'dark' : 'light';
                    localStorage.setItem('theme', newMode);
                    return newMode;
                });
            },
            currentMode: mode,
        }),
        [mode]
    );
    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            updateToken(token);
        }
    }, []);
    useEffect(() => {
        const className = mode;
        document.body.classList.add(className);
        return () => {
            document.body.classList.remove(className);
        };
    }, [mode]);
    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        function setHeight() {
            vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setHeight();
        window.addEventListener('resize', setHeight);

        return () => {
            window.removeEventListener('resize', setHeight);
        };
    });
    useAutoLogout(15 * 60 * 1000, setOpenAutoLogout);
    return (
        <WithChat>
            <CustomSnackbarProvider>
                <DndProvider backend={HTML5Backend}>
                    <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <GlobalStyles styles={globalStyles} />
                            <AppContainer />
                            <ConnectEntityModal />
                            <AutoLogoutModal open={openAutoLogout} fn={Logout} />
                            {notifications.map(({ duration, id, action, closeHandler, message }) => {
                                return (
                                    <Snackbar
                                        key={id}
                                        autoHideDuration={duration}
                                        open
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        onClose={closeHandler}
                                        message={message}
                                        sx={{ whiteSpace: 'pre' }}
                                        action={
                                            <Button size={'small'} onClick={() => action?.handler(id)}>
                                                {action?.text}
                                            </Button>
                                        }
                                    />
                                );
                            })}
                        </ThemeProvider>
                    </ColorModeContext.Provider>
                </DndProvider>
            </CustomSnackbarProvider>
        </WithChat>
    );
};

export default App;
