import { createEffect, createStore } from 'effector';
import { distributionApiService } from '../../ApiAdapter';

const initialValue = {};
const distributionApi = distributionApiService();

const fetchDistributionsFx = createEffect(async () => {
    return distributionApi.getDistributions();
});

const $distributionsStore = createStore(initialValue).on(
    fetchDistributionsFx.doneData,
    (_, newData) => newData
);

export const distributionsStore = {
    $distributionsStore,
};

export const distributionsEffects = {
    fetchDistributionsFx,
};
