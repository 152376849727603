import React, { useEffect, useState } from 'react';
import { useDocumentsStorage } from '../../../services/StorageAdapter';
import { EnhancedTable, Loader } from '../../../shared/ui';
import { documentGeneratedHeaders } from '../consts';
import EmptyTable from '../../../shared/ui/EnhancedTable/EmptyTable';
import { Typography } from '@mui/material';
import AddGenerateDocumentModal from './Modals/AddGenerateDocumentModal';
import { useLocation } from 'react-router-dom';

const DocumentTemplates = ({ toggleModal, open }: { toggleModal: () => void; open: boolean }) => {
    const { fetchTemplateDocuments, fetchFunnels, fetchNumerators, documentTemplates, loadingTemplates } =
        useDocumentsStorage();
    const { search } = useLocation();
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        if (search) {
            toggleModal();
        }
    }, [search]);

    useEffect(() => {
        fetchTemplateDocuments({ limit: 10, offset: 0 });
    }, [success]);
    return (
        <>
            {loadingTemplates ? (
                <Loader sx={{ height: '100%' }} />
            ) : documentTemplates.results.length > 0 ? (
                <EnhancedTable
                    onUpdate={(params) => {
                        fetchTemplateDocuments({ limit: params.limit, offset: params.offset });
                    }}
                    header={documentGeneratedHeaders}
                    data={documentTemplates.results}
                    count={documentTemplates.count}
                    limit={documentTemplates.limit}
                    offset={documentTemplates.offset || 0}
                />
            ) : (
                <EmptyTable>
                    <>
                        <Typography variant="body1" sx={{ fontWeight: '500', fontSize: '20px' }} mb={1.5}>
                            Тип документов не созданы
                        </Typography>
                        <Typography variant="body2">
                            После создания типа документа перейдите на вкладку <br /> «Шаблоны» вверху и
                            создайте шаблон документа.
                        </Typography>
                    </>
                </EmptyTable>
            )}
            {open && (
                <AddGenerateDocumentModal
                    fetchFunnels={fetchFunnels}
                    fetchNumerators={fetchNumerators}
                    open={open}
                    onClose={toggleModal}
                    setSuccess={setSuccess}
                />
            )}
        </>
    );
};

export default DocumentTemplates;
