import React, { useState, MouseEvent } from 'react';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { Button } from 'shared/ui';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Menu, MenuItem, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface TableRelationListProps {
    items: ConnectedObjectType[];
    entity: string | undefined;
}

const TableRelationList = ({ items, entity }: TableRelationListProps) => {
    const { t } = useTranslation();
    const [ref, setRef] = useState<HTMLButtonElement | null>(null);
    const {
        palette: {
            action: { active },
        },
    } = useTheme();

    const open = Boolean(ref);
    const navigate = useNavigate();

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setRef(e.currentTarget);
    };
    const handleClose = () => setRef(null);

    const handleItemClick = (id: string, localEntity: string | undefined) => {
        const resultEntity = localEntity || entity;
        if (resultEntity) {
            if (resultEntity !== 'tasks') {
                navigate(`/entity/${resultEntity}/${id}`);
            } else {
                navigate(`/entity/${resultEntity}/?task_id=${id}`);
            }
        }
        handleClose();
    };

    return (
        <>
            <Button
                onClick={handleClick}
                sx={{ color: active }}
                variant={'text'}
                size={'small'}
                endIcon={<ArrowDropDownRoundedIcon />}
            >
                {t('severalObjectsCount', { count: items?.length })}
            </Button>
            {!!items?.length && (
                <Menu open={open} onClose={handleClose} anchorEl={ref}>
                    {items?.map(({ id, name, entity, fullname, title }) => {
                        return (
                            <MenuItem key={id} onClick={() => handleItemClick(id, entity)}>
                                {name || fullname || title || ''}
                            </MenuItem>
                        );
                    })}
                </Menu>
            )}
        </>
    );
};

export default TableRelationList;
