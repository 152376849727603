import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import uuid from 'react-uuid';
import { TFunction } from 'i18next';

export const drawerWidth = 283;

export const mockAdminMenus = (t: TFunction) => [
    {
        id: uuid(),
        uri: '/entity/tasks',
        name: t('tasks'),
    },
    {
        id: uuid(),
        name: t('trash'),
        uri: '/trash',
    },
    {
        id: uuid(),
        name: t('entityConstructor'),
        uri: '/constructor',
    },
    {
        id: uuid(),
        uri: '/users-and-roles',
        name: t('usersAndRoles'),
    },
    // {
    //     id: uuid(),
    //     name: t('reports'),
    //     uri: '/reports',
    // },
    {
        id: uuid(),
        uri: '/departments-control',
        name: t('departments'),
    },
    {
        id: uuid(),
        uri: '/import',
        name: t('importData'),
    },
    {
        id: uuid(),
        uri: '/distribution',
        name: 'Распределение объектов',
    },
    {
        id: uuid(),
        uri: '/distribution/employee',
        name: 'Распределение объектов по сотрудникам',
    },
    {
        id: uuid(),
        uri: '/template-documents',
        name: 'Шаблоны документов',
    },
    // {
    //     id: uuid(),
    //     uri: '/channels',
    //     name: t('communicationChannels'),
    // },
];

export const mockUserMenus = (t: TFunction) => [
    {
        id: uuid(),
        uri: '/entity/tasks',
        name: t('tasks'),
    },
    {
        id: uuid(),
        name: t('trash'),
        uri: '/trash',
    },
    // {
    //     id: uuid(),
    //     name: t('reports'),
    //     uri: '/reports',
    // },
    {
        id: uuid(),
        uri: '/users-and-roles',
        name: t('usersAndRoles'),
    },
    {
        id: uuid(),
        uri: '/departments-control',
        name: t('departments'),
    },
    // {
    //     id: uuid(),
    //     uri: '/channels',
    //     name: t('communicationChannels'),
    // },
];

export const SidebarNavLink = styled(NavLink)(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.primary,
    textDecoration: 'none',
}));

export const SidebarLink = styled('a')(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.primary,
    textDecoration: 'none',
}));
