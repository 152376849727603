import React from 'react';
import { Grid } from '@mui/material';
import { Button, Search } from '../../../shared/ui';
const Icon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
            fill="white"
        />
    </svg>
);
const TemplateSearch = ({ openModal, btnLabel }: { openModal: () => void; btnLabel: string }) => {
    return (
        <Grid container flexWrap="nowrap" gap={2} alignItems="center" justifyContent="space-between" mt={3}>
            <Grid item width="100%">
                <Search fullWidth placeholder={'Искать тип документов'} />
            </Grid>
            <Grid item>
                <Button
                    onClick={openModal}
                    variant="contained"
                    sx={{ marginLeft: '20px', borderRadius: 2, whiteSpace: 'nowrap' }}
                    startIcon={<Icon />}
                >
                    {btnLabel}
                </Button>
            </Grid>
        </Grid>
    );
};

export default TemplateSearch;
