import Api from './axios';
import { ICreateDocumentBody, IDocumentTypeList } from '../../domain/documents';
import { TParamsRequest } from './types';

export const documentsService = {
    getDocuments(params: TParamsRequest) {
        const url = `/api/v1/documents/document-types`;
        return Api.get<null, IDocumentTypeList>(url, { params }).then((res) => {
            return { ...res, params };
        });
    },
    getTemplatesDocuments(params: TParamsRequest) {
        return Api.get('/api/v1/documents/document-templates', { params }).then((res) => {
            return { ...res, ...params };
        });
    },
    createDocument(body: ICreateDocumentBody) {
        return Api.post('/api/v1/documents/document-types', body);
    },
    updateTypeDocument(body: { name: string; id: string }) {
        return Api.put(`/api/v1/documents/document-types/${body.id}`, {
            is_active: false,
            name: body.name,
        });
    },
    getNumerators() {
        return Api.get('/api/v1/documents/numerators');
    },
};
