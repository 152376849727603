import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Button, Input, Label, Select } from 'shared/ui';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ChangePassword from './ChangePassword';
import { useNotification, useUsersStorage } from 'services/StorageAdapter';
import ChangeAvatar from './ChangeAvatar';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import Logout from 'shared/helpers/Logout';
import RecoverPassword from 'widgets/Profile/ui/RecoverPassword';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { UserType } from '../../../domain/user';
import InputMask from 'shared/ui/InputMask/InputMask';
import { genders } from '../lib/const/genders';
import { CustomDialog, Transition } from '../lib/const/components';
import { notSpecified } from '../lib/helpers/notSpecified';
import { useTranslation } from 'react-i18next';
// import { Language } from './Language/Language';

export default function Profile({ open, user, handleClose, readOnly = false }: any) {
    const { t } = useTranslation();
    const { notify } = useNotification();
    const [edit, setEdit] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [showRecover, setShowRecover] = useState(false);
    const [openAvatar, setOpenAvatar] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { updateMe, patchUser, me, fetchUser } = useUsersStorage();
    const [head, setHead] = useState<UserType | null>(null);
    const [localAvatar, setLocalAvatar] = useState(null);
    const {
        palette: {
            action: { active },
        },
    } = useTheme();

    const onSave = () => {
        setIsLoading(true);
        interface IUserData {
            [key: string]: string | null | undefined;
        }
        const user_data: IUserData = {
            last_name: me?.last_name,
            first_name: me?.first_name,
            surname: me?.surname,
            phone_number: me?.phone_number && `+${me?.phone_number?.replace(/[^\d]/g, '')}`,
            avatar: localAvatar || me?.avatar,
            gender: me?.gender,
            id: me?.id,
        };

        patchUser(user_data)
            .then((res) => {
                updateMe(res);
                setEdit(!edit);
                notify({ message: t('userDataUpdated') });
            })
            .catch((err) => {
                const error: string[] = Object.values(err.response.data);
                error.length
                    ? error.map((e: string) => notify({ message: e }))
                    : notify({ message: t('somethingWentWrong') });
            })
            .finally(() => setIsLoading(false));
    };

    const onChange = (value: string, type: string) => {
        updateMe({ ...user, [type]: value });
    };

    // const handleShowAllSubordinates = () => {
    //     setShowAllSubordinates((prev) => !prev);
    // };

    useEffect(() => {
        if (localAvatar && !edit) {
            setLocalAvatar(null);
        }
    }, [edit]);
    useEffect(() => {
        if (user.department && open) {
            fetchUser(user.department.head).then((res) => setHead(res));
        }
    }, [user.department, open]);
    return (
        <CustomDialog
            fullScreen
            sx={{ maxWidth: '612px', left: 'auto' }}
            open={open}
            onClose={() => {
                handleClose();
                setLocalAvatar(null);
            }}
            TransitionComponent={Transition}
        >
            <Box
                sx={{
                    position: 'fixed',
                }}
            >
                <Button
                    startIcon={<CloseRoundedIcon />}
                    variant={'contained'}
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '-140px',
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                    }}
                >
                    {t('close')}
                </Button>
            </Box>
            <Grid
                px={'24px'}
                py={'28px'}
                sx={{
                    border: '1px solid rgba(0, 0, 0, 0.16)',
                    borderRadius: '12px',
                    mb: '24px',
                    mt: '10px',
                }}
            >
                <Grid container flexDirection={'column'} gap={'12px'}>
                    <Grid container justifyContent="space-between">
                        {edit ? (
                            <>
                                <Grid mb={'24px'} xs={12}>
                                    <Label variant="subtitle2" label={t('username')} isRequired={true} />
                                    <Input
                                        fullWidth
                                        value={user.first_name}
                                        onChange={(e) => onChange(e.target.value, 'first_name')}
                                        placeholder={t('enterFirstName')}
                                    />
                                </Grid>
                                <Grid mb={'24px'} xs={12}>
                                    <Label variant="subtitle2" label={t('userLastName')} isRequired={true} />
                                    <Input
                                        fullWidth
                                        value={user.last_name}
                                        onChange={(e) => onChange(e.target.value, 'last_name')}
                                        placeholder={t('enterLastName')}
                                    />
                                </Grid>
                                <Grid mb={'24px'} xs={12}>
                                    <Label variant="subtitle2" label={t('userMiddleName')} />
                                    <Input
                                        fullWidth
                                        value={user.surname}
                                        onChange={(e) => onChange(e.target.value, 'surname')}
                                        placeholder={t('enterMiddleName')}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Typography variant={'h6'}>
                                    {user.last_name} {user.first_name} {user?.surname}{' '}
                                </Typography>
                                {me.id === user.id && !readOnly && (
                                    <Button onClick={() => setEdit(!edit)}>{t('change')}</Button>
                                )}
                            </>
                        )}
                    </Grid>
                    {me.id !== user.id && !user.deactivated ? (
                        <Grid container gap={'12px'}>
                            <Button
                                size={'small'}
                                startIcon={<PhoneEnabledRoundedIcon />}
                                variant={'outlined'}
                            >
                                {t('call')}
                            </Button>
                            <Button
                                size={'small'}
                                startIcon={<MailRoundedIcon />}
                                endIcon={<ArrowDropDownRoundedIcon />}
                                variant={'outlined'}
                            >
                                {t('write')}
                            </Button>
                        </Grid>
                    ) : (
                        me.id === user.id &&
                        user.deactivated && (
                            <Button size={'small'} startIcon={<HistoryRoundedIcon />} variant={'outlined'}>
                                {t('messageHistory')}
                            </Button>
                        )
                    )}
                </Grid>
                <Divider sx={{ my: '24px' }} />
                <Grid container spacing={3}>
                    <Grid item xs={6} sx={{ position: 'relative' }}>
                        {edit && (
                            <IconButton
                                component="label"
                                onClick={() => setOpenAvatar(true)}
                                sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    right: -14,
                                    top: 8,
                                    background: active,
                                    boxShadow:
                                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                                }}
                            >
                                <CameraAltIcon />
                            </IconButton>
                        )}
                        <Avatar
                            variant="rounded"
                            alt={user.first_name}
                            src={localAvatar || user?.avatar}
                            sx={{ width: '100%', height: 204 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid mb="24px">
                            <Label variant="subtitle2" label={t('userEmail')} />
                            <Button sx={{ p: 0 }} disabled={edit}>
                                {user.email}
                            </Button>
                        </Grid>
                        <Grid mb="24px">
                            <Label variant="subtitle2" label="Телефон" />
                            {edit ? (
                                <TextField
                                    InputProps={{ inputComponent: InputMask }}
                                    onChange={(e) => onChange(e.target.value, 'phone_number')}
                                    value={user.phone_number}
                                />
                            ) : user.phone_number ? (
                                <Typography>{user.phone_number}</Typography>
                            ) : (
                                notSpecified('24px')
                            )}
                        </Grid>
                        <Grid container justifyContent="space-between" flexWrap={'wrap'}>
                            <Grid item xs={7} mb={3}>
                                <Label variant="subtitle2" label="Пол" />
                                {edit ? (
                                    <Select
                                        items={genders}
                                        value={user?.gender || 0}
                                        onChange={(e) =>
                                            onChange(
                                                genders.find((gender: any) => gender.id === e.target.value)
                                                    .id,
                                                'gender'
                                            )
                                        }
                                        sx={{ fontSize: 14 }}
                                    />
                                ) : user.gender ? (
                                    <Typography>
                                        {genders.find((gender: any) => gender.id === user.gender)?.text}
                                    </Typography>
                                ) : (
                                    notSpecified()
                                )}
                            </Grid>
                            {/*<Language edit={edit} />*/}

                            <Grid item xs={7}>
                                <Label variant="subtitle2" label="Статус" />
                                <Typography>
                                    <CircleRoundedIcon
                                        color={user.is_active ? 'primary' : 'error'}
                                        sx={{ mr: 1, mb: '-4px' }}
                                        fontSize="small"
                                    />
                                    {user.is_active ? t('active') : 'Деактивирован'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                px={'24px'}
                py={'28px'}
                sx={{ border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '12px', mb: '24px' }}
            >
                <Typography variant={'h6'}>{t('departmentAndPosition')}</Typography>
                <Divider sx={{ my: '24px' }} />
                <Label variant="subtitle2" label={t('department')} title="" />
                {user.employee?.department ? (
                    <Typography sx={{ mb: '24px' }}>{user.employee?.department?.name}</Typography>
                ) : (
                    notSpecified('24px')
                )}
                <Label variant="subtitle2" label="Должность" title="" />
                {user?.position === null || user.position === 'null' ? (
                    notSpecified('24px')
                ) : (
                    <Typography sx={{ mb: '24px' }}>{user.position}</Typography>
                )}
            </Grid>
            <Grid
                px={'24px'}
                py={'28px'}
                sx={{ border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '12px', mb: '24px' }}
            >
                <Typography variant={'h6'}>{t('additionalInfo')}</Typography>
                <Divider sx={{ my: '24px' }} />
                {/*<Label variant="subtitle2" label="Подчинённые" />*/}
                {/*<Grid container mb="20px" flexWrap={'wrap'} gap={'8px'}>*/}
                {/*    {(showAllSubordinates ? subordinates : subordinates.slice(0, 3)).map(*/}
                {/*        ({ name }, index) => {*/}
                {/*            return (*/}
                {/*                <Chip*/}
                {/*                    key={index}*/}
                {/*                    avatar={*/}
                {/*                        <Avatar alt="Айша Омарқызы Асан" src="/static/images/avatar/1.jpg" />*/}
                {/*                    }*/}
                {/*                    label={name}*/}
                {/*                    variant="outlined"*/}
                {/*                />*/}
                {/*            );*/}
                {/*        }*/}
                {/*    )}*/}
                {/*    {subordinates?.length > 3 && !showAllSubordinates && (*/}
                {/*        <Chip label="+99 файлов" variant="outlined" />*/}
                {/*    )}*/}
                {/*    <Box width={'100%'}>*/}
                {/*        <Button*/}
                {/*            endIcon={*/}
                {/*                showAllSubordinates ? (*/}
                {/*                    <KeyboardArrowUpRoundedIcon />*/}
                {/*                ) : (*/}
                {/*                    <KeyboardArrowDownRoundedIcon />*/}
                {/*                )*/}
                {/*            }*/}
                {/*            sx={{ p: 0, color: alpha(active, 0.54) }}*/}
                {/*            onClick={handleShowAllSubordinates}*/}
                {/*        >*/}
                {/*            {showAllSubordinates ? 'Свернуть' : 'Развернуть'}*/}
                {/*        </Button>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
                <Label variant="subtitle2" label={t('supervisor')} />
                {head ? (
                    <Stack direction="row" spacing={1}>
                        <Chip
                            avatar={<Avatar alt={`${head.first_name} ${head.last_name}`} src={head.avatar} />}
                            label={`${head.first_name} ${head.last_name}`}
                            variant="outlined"
                        />
                    </Stack>
                ) : (
                    notSpecified('24px')
                )}
            </Grid>
            {!readOnly ? (
                <Grid
                    px={'24px'}
                    py={'28px'}
                    sx={{ border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '12px', mb: '24px' }}
                >
                    <Typography variant={'h6'}>{t('password')}</Typography>
                    <Divider sx={{ my: '24px' }} />
                    <Grid container gap={'8px'} flexWrap={'nowrap'}>
                        <Button variant="contained" onClick={() => setShow(true)}>
                            {t('changePassword')}
                        </Button>
                        <Button variant="outlined" onClick={() => setShowRecover(true)}>
                            {t('forgotYourPassword')}
                        </Button>
                    </Grid>
                </Grid>
            ) : null}
            {show && <ChangePassword open={show} user={user} onClose={() => setShow(false)} />}
            {showRecover && <RecoverPassword open={showRecover} onClose={() => setShowRecover(false)} />}
            {openAvatar && (
                <ChangeAvatar
                    open={openAvatar}
                    user={user}
                    setLocalAvatar={setLocalAvatar}
                    localAvatar={localAvatar}
                    onUpdate={updateMe}
                    onClose={() => setOpenAvatar(false)}
                />
            )}
            {edit ? (
                <>
                    <Divider variant="fullWidth" />
                    <Grid container gap={2} mt={3} justifyContent="flex-end">
                        <Button variant="contained" onClick={() => onSave()} loading={isLoading}>
                            {t('saveChange')}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setEdit(!edit);
                                setLocalAvatar(null);
                            }}
                        >
                            {t('exitWithoutSaving')}
                        </Button>
                    </Grid>
                </>
            ) : !readOnly ? (
                <Grid
                    px={'24px'}
                    py={'28px'}
                    sx={{ border: '1px solid rgba(0, 0, 0, 0.16)', borderRadius: '12px', mb: '24px' }}
                >
                    <Button variant="text" color="error" startIcon={<LogoutIcon />} onClick={Logout}>
                        {t('signOut')}
                    </Button>
                </Grid>
            ) : null}
        </CustomDialog>
    );
}
