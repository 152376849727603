import React from 'react';
import { Button, Modal } from '../../shared/ui';
import { alpha, Box, Grid, Typography } from '@mui/material';

interface IProps {
    open: boolean;
    fn: () => void;
}

const Icon = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="59" height="59" rx="8" fill="#2E7D32" fillOpacity="0.12" />
        <path
            d="M29.9854 15.4167C21.9354 15.4167 15.4167 21.95 15.4167 30C15.4167 38.05 21.9354 44.5833 29.9854 44.5833C38.05 44.5833 44.5833 38.05 44.5833 30C44.5833 21.95 38.05 15.4167 29.9854 15.4167ZM30 41.6667C23.5542 41.6667 18.3333 36.4458 18.3333 30C18.3333 23.5542 23.5542 18.3333 30 18.3333C36.4458 18.3333 41.6667 23.5542 41.6667 30C41.6667 36.4458 36.4458 41.6667 30 41.6667Z"
            fill="#2E7D32"
        />
        <path
            d="M30.7292 22.7083H28.5417V31.4583L36.1979 36.0521L37.2917 34.2583L30.7292 30.3646V22.7083Z"
            fill="#2E7D32"
        />
    </svg>
);
const AutoLogoutModal = (props: IProps) => {
    const { open, fn } = props;
    return (
        <Modal open={open} width={700}>
            <Grid display="flex" justifyContent="center" alignItems="center">
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Icon />
                    <Typography variant="h4">Время сеанса истекло!</Typography>
                    <Typography variant="body1" sx={{ margin: '20px 0', color: alpha('#000', 0.6) }}>
                        Вы не были активны на своем аккаунте в течение <br /> длительного времени. Для вашей
                        безопасности, <br /> сеанс был автоматически прекращен.
                    </Typography>
                    <Button variant="contained" onClick={fn}>
                        Авторизация
                    </Button>
                </Box>
            </Grid>
        </Modal>
    );
};

export default AutoLogoutModal;
