import React, { useEffect, useState } from 'react';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { Button } from '../../../../../shared/ui';
import Axios from 'shared/api/axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ContentCopy } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
const Settings = ({
    documentId,
    handleChangeStep,
}: {
    documentId: string | null;
    handleChangeStep: (arg: string) => void;
}) => {
    const [data, setData] = useState([]);
    const [currentIdx, setCurrentIdx] = useState<any>('');
    useEffect(() => {
        Axios.get(`/api/v1/documents/document-templates/${documentId}/available-fields`).then(
            (result: any) => {
                setData(result);
            }
        );
    }, []);

    const copyClipboard = async (code: string, idx: number) => {
        navigator.clipboard.writeText(code);
        setCurrentIdx(idx);
        setTimeout(() => {
            setCurrentIdx('');
        }, 3000);
    };
    return (
        <Grid sx={{ padding: '30px' }}>
            <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                Скопируйте ID ниже и вставьте его в ваш шаблон документа. При создании документа ID будет
                автоматически заменен значениями из сущностей CRM.
            </Typography>
            <Paper>
                <Grid
                    container
                    sx={{
                        background: '#f9f9f9',
                        padding: '10px 20px',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                    }}
                >
                    <Grid item md={6}>
                        <Typography variant="body1">Поле в сущности</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant="body1">ID поля</Typography>
                    </Grid>

                    <Divider />
                </Grid>
                {data.map((item: any, index: number) => (
                    <Grid
                        key={item.id}
                        container
                        sx={{
                            height: '48px',
                            borderTop: '1px solid #d6d6d6',
                            borderBottom: data.length - 1 === index ? 'none' : '1px solid #d6d6d6',
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            md={6}
                            sx={{
                                paddingLeft: '20px',
                            }}
                        >
                            {item.name}
                        </Grid>
                        <Grid
                            item
                            md={6}
                            onClick={() => copyClipboard(item.code, index)}
                            sx={{
                                cursor: 'pointer',
                                paddingRight: '20px',
                                height: '100%',
                                background: currentIdx === index ? '#e6efe6' : 'transparent',
                                ':hover': { background: currentIdx === index ? '#e6efe6' : '#f9f9f9' },
                            }}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {currentIdx === index ? (
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: ' 20px' }}>
                                    <CheckIcon color="success" />
                                    <div style={{ marginLeft: '10px' }}>Скопирован</div>
                                </div>
                            ) : (
                                <>
                                    {`{${item.code}}`}
                                    <div>
                                        <ContentCopy />
                                    </div>
                                </>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Paper>
            <Grid container justifyContent="flex-end" mt={3}>
                <Button
                    onClick={() => handleChangeStep('prev')}
                    variant="outlined"
                    sx={{ marginRight: '20px' }}
                    startIcon={<ArrowBackIcon />}
                >
                    Назад
                </Button>
                <Button type="submit" variant="contained" onClick={() => handleChangeStep('next')}>
                    Далее
                </Button>
            </Grid>
        </Grid>
    );
};

export default Settings;
