import {
    IAuthStorageService,
    ICalendarStorage,
    IConnectEntityModalStorageService,
    IDataStorageService,
    IDepartmentsStorageService,
    IDocumentsStorage,
    IEmployeesStorageService,
    IEntityDetailStorageService,
    IEntityStorageService,
    IFieldsStorageService,
    IFunnelsListStorageService,
    IFunnelsStorageService,
    IFunnelViewStorageService,
    IMetaStorageService,
    INotificationsService,
    IOrganizationStorageService,
    IPermissionsStorageService,
    IReportDetailStorage,
    IRolesStorageService,
    ITaskDetailStorageService,
    IUsersStorageService,
    NotifyProps,
} from 'application/ports/Storage';
import { rolesEffects, rolesEvents, rolesStores } from './store/roles/roles';
import { usersEffects, usersEvents, usersStores } from './store/users/users';
import { useStore } from 'effector-react';
import { entityEffects, entityEvents, entityStores } from 'services/store/entity/entity';
import { Field, FieldsGroup, FieldValueType } from 'domain/field';
import uuid from 'react-uuid';
import { metaEffects, metaEvents, metaStores } from './store/meta/meta';
import { funnelsEffects, funnelsEvents, funnelsStores } from './store/funnels/funnels';
import { dataEffects, dataEvents, dataStores } from './store/data/data';
import { TStages } from 'domain/funnels';
import { funnelsListEffects, funnelsListEvents, funnelsListStores } from './store/funnels/funnels-list';
import { authEvents, authStores } from 'services/store/auth/auth';
import { departmentEffects, departmentEvents, departmentStores } from 'services/store/department/department';
import { notificationsEvents, notificationsStores } from 'services/store/notifications/notifications';
import {
    permissionsEffects,
    permissionsEvents,
    permissionsStores,
} from 'services/store/permissions/permissions';
import { wsEffects, wsEvents, wsStores } from './store/ws/ws';
import { Centrifuge } from 'centrifuge';
import {
    organizationEffects,
    organizationEvents,
    organizationStores,
} from './store/organization/organization';
import decode from 'jwt-decode';
import { fieldsEffects, fieldsEvents, fieldsStores } from './store/fields/fields';
import {
    localEntityDetailEffects,
    localEntityDetailEvents,
    localEntityDetailStores,
} from 'services/store/entityDetail/entityDetailLocal';
import {
    entityDetailEffects,
    entityDetailEvents,
    entityDetailStores,
} from 'services/store/entityDetail/entityDetail';
import { EntityData } from 'domain/entity';
import { Nullable } from 'shared/types';
import { useCallback, useMemo } from 'react';
import { employeesEffects, employeesStores } from 'services/store/employee/employee';
import { combine, sample } from 'effector';
import { funnelViewEffects, funnelViewEvents, funnelViewStores } from './store/funnel-view/funnel-view';
import { saveAs } from 'file-saver';
import { taskEffects, taskEvents, taskStores } from 'services/store/tasks/tasks';
import { calendarEffects, calendarEvents, calendarStores } from 'services/store/calendar/calendar';
import i18n from '../shared/config/i18n/i18n';
import { reportEffects, reportEvents, reportStores } from 'services/store/report/report';
import { documentEffects, documentsStore } from './store/documents/documents';
import { distributionsEffects, distributionsStore } from './store/distributions/distributions';

const user: null | { user_id: string } = localStorage?.getItem('token')
    ? decode(localStorage?.getItem('token') || '')
    : null;

const { $permissionsStore } = permissionsStores;
const { updatePermissions, clearPermissions } = permissionsEvents;
const { fetchPermissionsFx, updatePermissionFx, deletePermissionFx, createPermissionFx } = permissionsEffects;

export function usePermissionsStorage(): IPermissionsStorageService {
    const permissions: any = useStore($permissionsStore);
    const onDeletePermissions = (ids: any) => {
        ids.map((id: string) => {
            deletePermissionFx(id);
        });
    };

    return {
        permissions,
        clearPermissions,
        updatePermissions,
        updatePermission: updatePermissionFx,
        fetchPermissions: fetchPermissionsFx,
        deletePermission: onDeletePermissions,
        createPermission: createPermissionFx,
    };
}

const { $departmentsStore, $departmentStore, $distributionDepartments } = departmentStores;
const { updateDepartments, clearDepartments, clearDepartment } = departmentEvents;
const {
    fetchDepartmentsFx,
    getDepartmentFx,
    updateDepartmentFx,
    deleteDepartmentFx,
    createDepartmentFx,
    fetchDistributionDepartmentsFx,
} = departmentEffects;

export function useDepartmentsStorage(): IDepartmentsStorageService {
    const departments = useStore($departmentsStore);
    const department = useStore($departmentStore);
    const distributionDepartments = useStore($distributionDepartments);
    const updateDepartment = (departmentId: string, data: any) => {
        return updateDepartmentFx({ departmentId, data });
    };

    const fetchDepartments = (params?: any) => {
        return fetchDepartmentsFx({ params });
    };

    return {
        departments,
        updateDepartments,
        clearDepartments,
        clearDepartment,
        updateDepartment,
        fetchDepartments,
        createDepartment: createDepartmentFx,
        deleteDepartment: deleteDepartmentFx,
        getDepartment: getDepartmentFx,
        fetchDistributionDepartments: fetchDistributionDepartmentsFx,
        distributionDepartments,
        department,
    };
}

const { $rolesStore, $roleStore } = rolesStores;
const { updateRoles, clearRoles, updateRole, clearRole, putRole, createRole } = rolesEvents;
const { fetchRolesFx, fetchRoleFx, deleteRoleFx } = rolesEffects;

export function useRolesStorage(): IRolesStorageService {
    const roles = useStore($rolesStore);
    const role = useStore($roleStore);
    const loading = useStore(fetchRolesFx.pending);

    const onUpdateRoles = ({ id, type }: { id: string; type: string }) => {
        if (type === 'delete') {
            updateRoles({ ...roles, results: roles.results.filter((d: any) => d.id !== id) });
        }
    };

    return {
        role,
        updateRole,
        clearRole,
        fetchRole: fetchRoleFx,
        update: putRole,
        create: createRole,
        roles,
        updateRoles: onUpdateRoles,
        clearRoles,
        fetchRoles: fetchRolesFx,
        deleteRole: deleteRoleFx,
        loading,
    };
}

const { $usersStore, $userStore, $meStore, $headStore } = usersStores;
const { updateUsers, clearUsers, updateUser, clearUser, update, create, updateMe, clearMe, putMe } =
    usersEvents;
const {
    fetchUsersFx,
    deactivateUsersFx,
    activateUserFx,
    fetchUserFx,
    fetchMeFx,
    resetPasswordFx,
    verifyPasswordFx,
    createUserFx,
    updateUserFx,
    changePasswordFx,
    fetchHeadFx,
} = usersEffects;

export function useUsersStorage(): IUsersStorageService {
    const users = useStore($usersStore);
    const user = useStore($userStore);
    const me = useStore($meStore);
    const head = useStore($headStore);

    return {
        me,
        fetchMe: fetchMeFx,
        updateMe,
        clearMe,
        putMe,
        users,
        updateUsers,
        clearUsers,
        fetchUsers: fetchUsersFx,
        user,
        head,
        update,
        create,
        updateUser,
        clearUser,
        fetchUser: fetchUserFx,
        deactivateUsers: deactivateUsersFx,
        activateUser: activateUserFx,
        resetPassword: resetPasswordFx,
        verifyPassword: verifyPasswordFx,
        createUser: createUserFx,
        patchUser: updateUserFx,
        changePassword: changePasswordFx,
        fetchHead: fetchHeadFx,
    };
}

const { $entityStore } = entityStores;
const { updateEntity, clearEntity } = entityEvents;
const { fetchEntityFx, fetchEntityCreateFx, fetchEntityUpdateFx, exportDataFx } = entityEffects;

export function useEntityStorage(): IEntityStorageService {
    const entity = useStore($entityStore);

    const getFieldsGroup = (code: string) =>
        entity.fieldGroups.find((group) => group.id === code) as FieldsGroup;

    const updateFieldsGroup = (group: FieldsGroup) => {
        updateEntity({
            ...entity,
            fieldGroups: entity.fieldGroups.map((localGroup) =>
                localGroup.id === group.id ? group : localGroup
            ),
        });
    };

    const updateField = (group: FieldsGroup, field: Field) => {
        const copyGroup = { ...group };
        copyGroup.fields = copyGroup.fields.map((localField) =>
            localField.id === field.id ? field : localField
        );
        updateFieldsGroup(copyGroup);
    };

    const updateFieldsGroups = (groups: Array<FieldsGroup>) => {
        updateEntity({
            ...entity,
            fieldGroups: groups,
        });
    };

    const updateFields = (group: FieldsGroup, fields: Array<Field>) => {
        updateEntity({
            ...entity,
            fieldGroups: entity.fieldGroups.map((localGroup) => {
                if (localGroup.id === group.id) {
                    localGroup.fields = fields;
                }
                return localGroup;
            }),
        });
    };

    const createNewGroup = () => {
        const newGroup: FieldsGroup = {
            id: '',
            name: '',
            fields: [],
            isEdit: true,
            order: 10,
        };
        updateFieldsGroups([...entity.fieldGroups, newGroup]);
        return newGroup;
    };

    const deleteGroup = (groupCode: string) => {
        updateFieldsGroups([...entity.fieldGroups.filter((group) => group.id !== groupCode)]);
    };

    const createNewField = (group: FieldsGroup, type: FieldValueType, field?: Field) => {
        const newField: Field = {
            name: 'Название поля',
            placeholder: 'Введите название поля',
            type: 'system',
            value_type: type,
            group: '',
            id: uuid(),
            order: 0,
            settings: {},
            hidden: false,
            ordering: false,
            group_label: '',
            code: '',
            ...field,
        };
        updateFieldsGroup({
            ...group,
            fields: [...group.fields, newField],
        });
        return newField;
    };

    const deleteField = (group: FieldsGroup, fieldId: string) => {
        updateFieldsGroup({
            ...group,
            fields: group.fields.filter((field) => field.id !== fieldId),
        });
    };

    const exportData = async ({
        code,
        ...data
    }: {
        code: string;
        all_fields: boolean;
        format: string;
        funnel_id: string;
        name: string;
    }) => {
        const { name, ...otherData } = data;
        exportDataFx({ code, ...otherData }).then((res) => {
            const blob = new Blob([res], { type: `application/${data.format}` });
            saveAs(URL.createObjectURL(blob), `${name}.${data.format}`);
        });
    };

    return {
        entity,
        updateEntity,
        clearEntity,
        fetchEntity: fetchEntityFx,
        fetchEntityCreate: fetchEntityCreateFx,
        fetchEntityUpdate: fetchEntityUpdateFx,
        getFieldsGroup,
        updateField,
        updateFieldsGroup,
        updateFieldsGroups,
        updateFields,
        createNewGroup,
        deleteGroup,
        createNewField,
        deleteField,
        exportData,
    };
}

const { $metaStore, $metasStore } = metaStores;
const { updateMeta, clearMeta, updateMetas, clearMetas } = metaEvents;
const {
    fetchMetaFx,
    fetchMetasFx,
    createMetaFx,
    updateMetaFx,
    deleteMetaFx,
    switchHideFieldStatusFx,
    fetchMetaUpdaterFx,
} = metaEffects;

export function useMetaStorage(): IMetaStorageService {
    const meta = useStore($metaStore);
    const metas = useStore($metasStore);
    const loading = useStore(combine([fetchMetaFx.pending, fetchMetasFx.pending], ([a, b]) => a || b));
    const deleteLoading = useStore(deleteMetaFx.pending);
    const changeMetaTab = (change: string) => {
        updateMeta({ ...meta, tab: change });
    };

    return {
        metas,
        clearMetas,
        updateMetas,
        fetchMetas: fetchMetasFx,
        deleteMeta: deleteMetaFx,
        createMeta: createMetaFx,
        changeMeta: updateMetaFx,
        meta,
        clearMeta,
        updateMeta,
        fetchMeta: fetchMetaFx,
        fetchMetaUpdater: fetchMetaUpdaterFx,
        changeMetaTab,
        switchHideFieldStatus: switchHideFieldStatusFx,
        loading,
        deleteLoading,
    };
}

const { $funnelViewStore } = funnelViewStores;
const { clearFunnelView, updateFunnelView } = funnelViewEvents;
const { getViewTypeFx, createViewTypeFx, setViewTypeFx, getUserFunnelViewFx } = funnelViewEffects;

export function useFunnelViewStorage(): IFunnelViewStorageService {
    const funnelView = useStore($funnelViewStore);

    sample({
        clock: setViewTypeFx.done,
        filter: () => true,
        fn: ({ result }) => ({
            ...funnelView,
            results: funnelView.results.find((field) => field.id === result.id)?.id
                ? funnelView.results?.map((field) => (field.id === result.id ? { ...result } : { ...field }))
                : [...funnelView.results, result],
        }),
        target: updateFunnelView,
    });

    sample({
        clock: createViewTypeFx.done,
        filter: () => true,
        fn: ({ result }) => ({
            ...funnelView,
            results: funnelView.results.find((field) => field.id === result.id)?.id
                ? funnelView?.results
                      ?.filter((item) => item.id)
                      ?.map((field) => (field.id === result.id ? { ...result } : { ...field }))
                : [...funnelView?.results?.filter((item) => item.id), result],
        }),
        target: updateFunnelView,
    });

    return {
        funnelView,
        getViewType: getViewTypeFx,
        createViewType: createViewTypeFx,
        setViewType: setViewTypeFx,
        getUserFunnelView: getUserFunnelViewFx,
        updateFunnelView,
        clearFunnelView,
    };
}

const { $funnelsStore } = funnelsStores;
const { updateFunnels, clearFunnels } = funnelsEvents;
const { fetchFunnelsIDFx, fetchFunnelsStagesFx, fetchFunnelsStagesIDFx, fetchFunnelsFunnelsIDFx } =
    funnelsEffects;

export function useFunnelsStorage(): IFunnelsStorageService {
    const funnels = useStore($funnelsStore);
    const loading = useStore(fetchFunnelsIDFx.pending);

    const updateFunnelsStages = (result: any, id?: string) => {
        updateFunnels({
            ...funnels,
            stages: funnels.stages.map((stage) =>
                stage?.id === id ? { ...stage, ...result } : { ...stage }
            ),
        });
    };

    const dragFunnelsStage = (result: any) => {
        const { destination, source, draggableId, type } = result;
        if (!destination) {
            return;
        }
        if (type === 'list') {
            // перенос колонок
            return;
        }

        const sourceList = funnels.stages.find((stage) => stage?.id === source.droppableId);
        const destinationList = funnels.stages.find((stage) => stage?.id === destination.droppableId)!;
        const draggingCard = sourceList?.results?.find((card) => card.id === draggableId);

        sourceList?.results?.splice(source.index, 1);
        destinationList?.results?.splice(destination.index, 0, draggingCard);
        const newStages = funnels.stages.map((stage) =>
            stage.id === destination?.droppableId ? destinationList : stage
        );

        const entity_objects = {
            entity_objects: [draggingCard.id],
        };
        return { stages: newStages, id: destinationList?.id, entity_objects };
    };

    const selectFunnelsStage = (stage?: TStages, card_id?: string) => {
        const newStages = funnels.stages.map((funnel) =>
            funnel?.id === stage?.id
                ? {
                      ...funnel,
                      results: funnel?.results?.map((card) =>
                          card.id === card_id ? { ...card, selected: !card.selected } : card
                      ),
                  }
                : funnel
        );

        updateFunnels({
            ...funnels,
            stages: newStages,
        });
    };

    const getFunnelsStagesSelectedCount = () => {
        const count: boolean[] = [];
        funnels?.stages?.map((stage) =>
            stage?.results?.filter((card) => card?.selected && count.push(card?.selected))
        );
        return count?.length;
    };

    const getSelectedObjectIds = () => {
        const cards_ids: string[] = [];
        funnels?.stages?.map((stage) =>
            stage?.results?.map((card) => !!card?.selected && cards_ids.push(card.id))
        );
        return cards_ids;
    };

    const clearFunnelsStage = () => {
        const newStages = funnels.stages.map((funnel) => {
            return {
                ...funnel,
                results: funnel?.results?.map((card) =>
                    card.selected ? { ...card, selected: !card.selected } : card
                ),
            };
        });

        updateFunnels({
            ...funnels,
            stages: newStages,
        });
    };

    return {
        funnels,
        updateFunnels,
        clearFunnels,
        fetchFunnelsID: fetchFunnelsIDFx,
        loading,
        fetchFunnelsStagesID: fetchFunnelsStagesIDFx,
        fetchFunnelsFunnelsID: fetchFunnelsFunnelsIDFx,
        getStages: fetchFunnelsStagesFx,
        dragFunnelsStage,
        selectFunnelsStage,
        getSelectedObjectIds,
        clearFunnelsStage,
        getFunnelsStagesSelectedCount,
        updateFunnelsStages,
    };
}

const { $funnelsListStore } = funnelsListStores;
const { updateFunnelsList, clearFunnelsList } = funnelsListEvents;
const {
    fetchFunnelsListFx,
    updateFunnelsListFx,
    createFunnelsListFx,
    deleteFunnelsListFx,
    bulkUpdateFunnelFx,
    restoreFunnelsListFx,
    createStageFx,
    updateStageFx,
    deleteStageFx,
    bulkUpdateStageFx,
    getStagesFx,
} = funnelsListEffects;

export function useFunnelsListStorage(): IFunnelsListStorageService {
    const funnelsList = useStore($funnelsListStore);
    const funnel = useStore($entityFunnelStore);
    const entityFunnelStageModal = useStore($entityFunnelStageModalStore);
    const { notify } = useNotification();

    const onUpdateFunnelList = ({ data, type }: any) => {
        if (type === 'update') {
            updateFunnelsListFx(data).then(() => notify({ message: 'Воронка обновлена' }));
            updateFunnelsList({
                ...funnelsList,
                results: funnelsList.results.map((f: any) => {
                    if (f.id === data.id) {
                        f.name = data.name;
                    }
                    return f;
                }),
            });
        } else if (type === 'delete') {
            deleteFunnelsListFx(data.id).then(() => notify({ message: 'Воронка архивирована' }));
            updateFunnelsList({
                ...funnelsList,
                results: funnelsList.results.filter((f: any) => f.id !== data.id),
            });
        } else if (type === 'restore') {
            restoreFunnelsListFx(data.id).then(() => notify({ message: 'Воронка восстановлена' }));
            updateFunnelsList({
                ...funnelsList,
                results: funnelsList.results.filter((f: any) => f.id !== data.id),
            });
        } else if (type === 'create-stage') {
            createStageFx(data).then(() => notify({ message: 'Создан этап' }));
        } else if (type === 'update-stage') {
            updateStageFx({
                name: data.stage.name,
                color: data.stage.color,
                id: data.stage.id,
                funnel: data.id,
            }).then(() => notify({ message: 'Этап обновлен' }));
            updateFunnelsList({
                ...funnelsList,
                results: funnelsList.results.map((f: any) => {
                    if (f.id === data.id) {
                        f.stages = f.stages.map((s: any) => {
                            if (data.state === s.state.type) {
                                s.arr = s.arr.map((a: any) => {
                                    if (a.id === data.stage.id) {
                                        a = data.stage;
                                    }
                                    return a;
                                });
                            }
                            return s;
                        });
                    }
                    return f;
                }),
            });
        } else if (type === 'delete-stage') {
            deleteStageFx(data.stage.id)
                .then(() => {
                    notify({ message: 'Этап удален' });
                    updateFunnelsList({
                        ...funnelsList,
                        results: funnelsList.results.map((f: any) => {
                            if (f.id === data.id) {
                                return {
                                    ...f,
                                    stages: f.stages.map((stage: any) => {
                                        return {
                                            ...stage,
                                            arr: stage.arr.filter((a: any) => a.id !== data.stage.id),
                                        };
                                    }),
                                };
                            }
                            return f;
                        }),
                    });
                })
                .catch((err) => {
                    updateEntityFunnelStageModal({
                        isOpen: true,
                        data: data,
                    });
                    const error: string[] = Object.values(err.response.data);
                    error.length
                        ? error.map((e: string) => notify({ message: e }))
                        : notify({ message: i18n.t('somethingWentWrong') });
                });
        }
    };

    const onUpdateFunnelStage = ({ data, type }: any) => {
        if (type === 'update-stage') {
            updateStageFx({
                name: data.stage.name,
                color: data.stage.color,
                id: data.stage.id,
                funnel: data.id,
            })
                .then((res) => {
                    notify({ message: 'Этап изменен' });
                    const newFunnelStage = {
                        ...funnel,
                        stagesDefault: funnel.stages,
                        stages: funnel.stages.map((stage: any) => {
                            if (stage.state.type === res.state) {
                                return {
                                    ...stage,
                                    arr: stage.arr.map((i: any) => {
                                        if (i.id === res.id) {
                                            return {
                                                ...i,
                                                name: res.name,
                                            };
                                        }
                                        return i;
                                    }),
                                };
                            }
                            return stage;
                        }),
                    };
                    updateFunnelStage(newFunnelStage);
                })
                .catch((err) => {
                    const error: string[] = Object.values(err.response.data);
                    error.length
                        ? error.map((e: string) => notify({ message: e }))
                        : notify({ message: i18n.t('somethingWentWrong') });
                });
        } else if (type === 'delete-stage') {
            deleteStageFx(data.stage.id)
                .then(() => {
                    notify({ message: 'Этап удален' });
                    const newFunnelStage = {
                        ...funnel,
                        stagesDefault: funnel.stages,
                        stages: funnel.stages.map((stage: any) => {
                            if (stage.state.type === data.state) {
                                return {
                                    ...stage,
                                    arr: stage.arr.filter((i: any) => i.id !== data.stage.id),
                                };
                            }
                            return stage;
                        }),
                    };
                    updateFunnelStage(newFunnelStage);
                })
                .catch((err) => {
                    const error: string[] = Object.values(err.response.data);
                    error.length
                        ? error.map((e: string) => notify({ message: e }))
                        : notify({ message: i18n.t('somethingWentWrong') });
                });
        } else if (type === 'create-stage') {
            createStageFx(data)
                .then((res) => {
                    notify({ message: 'Этап создан' });
                    const newFunnelStage = {
                        ...funnel,
                        stagesDefault: funnel.stages,
                        stages: funnel.stages.map((stage: any) => {
                            if (stage.state.type === res.state) {
                                return {
                                    ...stage,
                                    arr: [...stage.arr, res],
                                };
                            }
                            return stage;
                        }),
                    };
                    updateFunnelStage(newFunnelStage);
                })
                .catch((err) => {
                    const error: string[] = Object.values(err.response.data);
                    error.length
                        ? error.map((e: string) => notify({ message: e }))
                        : notify({ message: i18n.t('somethingWentWrong') });
                });
        }
    };
    const dragStage = async ({ result, stages, funnel }: any) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        const reorder = (list: any, startIndex: any, endIndex: any) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };
        const items = reorder(stages, source.index, destination.index);
        const orderedItems = items.map((i: any, index) => ({ ...i, order: index }));
        const newFunnelList = {
            ...funnelsList,
            results: funnelsList.results.map((f: any) => {
                if (f.id === funnel.id) {
                    f.stages = f.stages.map((s: any) => {
                        if (s.state.type === 'in_progress') {
                            s.arr = orderedItems;
                        }
                        return s;
                    });
                }
                return f;
            }),
        };
        updateFunnelsList(newFunnelList);
        await bulkUpdateStageFx(orderedItems);
    };
    const dragFunnel = (result: any) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        const reorder = (list: any, startIndex: any, endIndex: any) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };
        const items = reorder(funnelsList.results, source.index, destination.index);

        const index = items.findIndex(function (funnel: any) {
            return funnel.type === 'backlog';
        });

        const element = items.splice(index, 1)[0];
        items.unshift(element);
        items.map((item: any, index: number) => {
            item.order = index;
            return item;
        });

        updateFunnelsList({ ...funnelsList, results: items });
        bulkUpdateFunnelFx(items);
    };
    // /funnels/stages/move
    // body: {
    //      funnel: funnel.id
    //      orders: items.map((item: any, index: number) => {
    //           return {id: item.id, order: index}
    //      })
    // }
    return {
        funnelsList,
        dragStage,
        dragFunnel,
        clearFunnelsList,
        updateFunnelsList: onUpdateFunnelList,
        fetchFunnelsList: fetchFunnelsListFx,
        getStages: getStagesFx,
        createFunnel: createFunnelsListFx,
        bulkUpdateStage: bulkUpdateStageFx,
        updateFunnelStage: onUpdateFunnelStage,
        entityFunnelStageModal,
        updateEntityFunnelStageModal,
    };
}

const { $dataStore, $entityDetailDataStore } = dataStores;
const { clearData, updateData, resetOffset } = dataEvents;
const {
    fetchDataCodeIDFx,
    deleteEntityFx,
    restoreEntityFx,
    fetchEntityDataFx,
    removeObjectFromEntityFx,
    bulkRestoreFx,
    bulkHardDeleteFx,
    fetchDataCodeUpdaterFx,
} = dataEffects;

export function useDataStorage(): IDataStorageService {
    const data = useStore($dataStore);
    const entityData = useStore($entityDetailDataStore);
    const loading = useStore(fetchDataCodeIDFx.pending);
    const onUpdateData = ({ id, type }: { id: string; type: string }) => {
        if (type === 'delete') {
            updateData({ ...data, results: data.results.filter((d: any) => d.id !== id) });
        }
    };

    return {
        entityData,
        data,
        clearData,
        resetOffset,
        fetchDataCodeUpdater: fetchDataCodeUpdaterFx,
        updateData: onUpdateData,
        fetchDataCodeID: fetchDataCodeIDFx,
        deleteEntity: deleteEntityFx,
        removeObjectFromEntity: removeObjectFromEntityFx,
        restoreEntity: restoreEntityFx,
        fetchEntityData: fetchEntityDataFx,
        bulkHardDelete: bulkHardDeleteFx,
        bulkRestore: bulkRestoreFx,
        loading,
    };
}

const { $fieldsEntityStore, $fieldsGroupsStore, $fieldsMetaStore, $metaFieldsParams, $entityFieldsParams } =
    fieldsStores;
const {
    fetchFieldsFx,
    fetchFieldsAddFx,
    fetchFieldsGroupsFx,
    deleteFieldFx,
    createEntityFieldFx,
    updateEntityGroupFieldFx,
    createFieldsGroupsFx,
    deleteFieldsGroupsFx,
    getFieldFx,
    updateFieldFx,
    updateMetaFieldFx,
    updateGroupFieldsFx,
    getMetaFieldFx,
    getFieldsMetaFx,
    createMetaFieldFx,
    bindMetaFieldsToEntityGroupFx,
    deleteFieldFromMetaFx,
    restoreFieldFromMetaFx,
    bulkHardDeleteFieldFromMetaFx,
    bulkRestoreFieldMetaFx,
    bulkUpdateEntityGroupsFx,
} = fieldsEffects;
const {
    updateFieldsEntity,
    clearFieldsEntity,
    updateFieldsMeta,
    clearFieldsMeta,
    updateFieldsGroups,
    clearFieldsGroups,
    resetParams: resetMetaFieldsParams,
} = fieldsEvents;

export function useFieldsStorage(): IFieldsStorageService {
    const loading = useStore(getFieldsMetaFx.pending);
    const loadingEntityFields = useStore(fetchFieldsFx.pending);
    const fieldsEntity = useStore($fieldsEntityStore);
    const fieldsGroups = useStore($fieldsGroupsStore);
    const fieldsMeta = useStore($fieldsMetaStore);
    const metaFieldsParams = useStore($metaFieldsParams);
    const entityFieldsParams = useStore($entityFieldsParams);

    const dragGroup = (result: any) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        const reorder = (list: any, startIndex: any, endIndex: any) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };
        const items: any[] = reorder(fieldsGroups.results, source.index, destination.index);

        items.map((item: any, index: number) => {
            item.order = index;
            return item;
        });

        updateFieldsGroups({ ...fieldsGroups, results: items });
        bulkUpdateEntityGroupsFx(items);
    };

    sample({
        clock: fetchFieldsFx.done,
        filter: () => true,
        fn: ({ params, result }) => ({
            ...fieldsGroups,
            results: fieldsGroups.results.map((field) =>
                field.id === params.group ? { ...field, fields: result } : { ...field }
            ),
        }),
        target: updateFieldsGroups,
    });

    return {
        loadingEntityFields,
        fieldsEntity,
        fetchFields: fetchFieldsFx,
        fetchFieldsAdd: fetchFieldsAddFx,
        fieldsGroups,
        fetchFieldsGroups: fetchFieldsGroupsFx,
        deleteField: deleteFieldFx,
        createEntityField: createEntityFieldFx,
        updateEntityGroupField: updateEntityGroupFieldFx,
        createFieldsGroups: createFieldsGroupsFx,
        deleteGroups: deleteFieldsGroupsFx,
        getField: getFieldFx,
        updateField: updateFieldFx,
        updateMetaField: updateMetaFieldFx,
        updateGroupFields: updateGroupFieldsFx,
        fieldsMeta,
        getMetaField: getMetaFieldFx,
        getFieldsMeta: getFieldsMetaFx,
        createMetaField: createMetaFieldFx,
        bindMetaFieldsToEntityGroup: bindMetaFieldsToEntityGroupFx,
        deleteFieldFromMeta: deleteFieldFromMetaFx,
        restoreFieldFromMeta: restoreFieldFromMetaFx,
        bulkHardDeleteFieldFromMeta: bulkHardDeleteFieldFromMetaFx,
        bulkRestoreFieldMeta: bulkRestoreFieldMetaFx,
        dragGroup,

        updateFieldsEntity,
        clearFieldsEntity,
        updateFieldsMeta,
        clearFieldsMeta,
        updateFieldsGroups,
        clearFieldsGroups,
        loading,
        resetMetaFieldsParams,
        metaFieldsParams,
        entityFieldsParams,
    };
}

const { updateLogin, updatePassword, submitForm, updateToken } = authEvents;
const { authFormStore, authStore, isAuthenticatingStore, authErrorsStore, isAuthenticatedStore } = authStores;

export function useAuthStorage(): IAuthStorageService {
    const { email, password } = useStore(authFormStore);
    const { accessToken, refreshToken } = useStore(authStore);
    const { email: emailError, password: passwordError, detail: generalError } = useStore(authErrorsStore);
    const isAuthenticating = useStore(isAuthenticatingStore);
    const isAuthenticated = useStore(isAuthenticatedStore);

    const login = () => {
        submitForm();
    };

    return {
        login,
        onLoginChange: updateLogin,
        onPasswordChange: updatePassword,
        email,
        password,
        accessToken,
        refreshToken,
        isAuthenticating,
        emailError,
        passwordError,
        generalError,
        updateToken,
        isAuthenticated,
    };
}

const { notify, removeNotification } = notificationsEvents;
const { notificationsStore } = notificationsStores;

export function useNotification(): INotificationsService {
    const notifications = useStore(notificationsStore);
    const notifyWrapper = ({ message, onClose, timer, action }: NotifyProps) => {
        const uuid4 = uuid();
        notify({
            message,
            id: uuid4,
            duration: timer || 3000,
            closeHandler: onClose,
            action,
        });
        return uuid();
    };

    return {
        notify: notifyWrapper,
        notifications,
        closeNotification: removeNotification,
    };
}

const { $wsStore } = wsStores;
const { updateWS, updateMessage } = wsEvents;
const { fetchConnectFx, fetchListFx, fetchFileFx, fetchMessagesFx, fetchRedirectFx } = wsEffects;

export function useWSStorage() {
    const chat = useStore($wsStore);

    const connectWS = (token: string) => {
        const centrifuge = new Centrifuge('wss://centrifugo.ndfs.kz/connection/websocket', {
            token: token,
        });
        centrifuge
            .on('connecting', function () {
                // console.log(`connecting: `, ctx);
            })
            .on('connected', function () {
                // console.log(`connected over `, ctx);
            })
            .on('disconnected', function () {
                // console.log(`disconnected: `, ctx);
            })
            .connect();
        updateWS({ ...chat, centrifuge: centrifuge });
    };

    const subscripToChannal = (channel_name: string) => {
        const subscribe = chat.centrifuge?.newSubscription(channel_name);
        subscribe
            ?.on('publication', function (ctx: any) {
                // console.log(`publication: `, ctx?.data);
                updateMessage(ctx?.data);
            })
            .on('subscribing', function () {
                // console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
            })
            .on('subscribed', function () {
                // console.log('subscribed', ctx);
            })
            .on('unsubscribed', function () {
                // console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
            })
            .subscribe();
        updateWS({ ...chat, subscribe: subscribe });
    };

    const publishMessage = (
        channel_uuid: string,
        text: string,
        replayed_message_uuid: string | null = null
    ) => {
        chat?.subscribe
            ?.publish({
                created_at: new Date(),
                replayed_message_uuid,
                channel_uuid,
                text,
                user: {
                    uuid: chat?.connect?.user_uuid,
                    name: chat?.connect?.user_name,
                    service_id: user?.user_id, // user uuid in srm
                    source: 'crm',
                },
            })
            .then(
                function () {
                    // console.log('successfully published', res);
                },
                function () {
                    // console.log('publish error', err);
                }
            );
    };

    return {
        chat,
        connectWS,
        subscripToChannal,
        publishMessage,
        fetchConnect: fetchConnectFx,
        fetchList: fetchListFx,
        fetchFile: fetchFileFx,
        fetchMessages: fetchMessagesFx,
        fetchRedirect: fetchRedirectFx,
    };
}

const { $organizationStore, $departmentEmployeeStore, $organizationFilterStore } = organizationStores;
const {
    updateOrganization,
    clearOrganization,
    updateDepartmentEmployee,
    clearDepartmentEmployee,
    clearOrganizationFilterStore,
    updateOrganizationFilterStore,
} = organizationEvents;
const {
    fetchOrganizationFx,
    fetchDepartmentEmployeeFx,
    setRoleFx,
    setPermissionsFx,
    unsetRoleFx,
    getFiltersUsersFx,
    getFilterUsersFx,
    createFilterUsersFx,
    updateFilterUsersFx,
    deleteFilterUsersFx,
} = organizationEffects;

export function useOrganizationStorage(): IOrganizationStorageService {
    const organization = useStore($organizationStore);
    const departmentEmployee = useStore($departmentEmployeeStore);
    const organizationFilter = useStore($organizationFilterStore);
    const organizationLoading = useStore(fetchOrganizationFx.pending);

    return {
        organization,
        departmentEmployee,
        updateOrganization,
        clearOrganization,
        updateDepartmentEmployee,
        clearDepartmentEmployee,
        fetchOrganization: fetchOrganizationFx,
        fetchDepartmentEmployee: fetchDepartmentEmployeeFx,
        setRole: setRoleFx,
        setPermissions: setPermissionsFx,
        unsetRole: unsetRoleFx,

        organizationFilter,
        getFiltersUsers: getFiltersUsersFx,
        getFilterUsers: getFilterUsersFx,
        createFilterUsers: createFilterUsersFx,
        updateFilterUsers: updateFilterUsersFx,
        deleteFilterUsers: deleteFilterUsersFx,
        clearOrganizationFilterStore,
        updateOrganizationFilterStore,
        organizationLoading,
    };
}

const {
    syncLocalTitle,
    updateLocalEntityData,
    syncLocalFields,
    syncLocalData,
    resetEntityDetail,
    changeStage,
    createGroup,
    deleteGroup,
    copyForm,
    resetEntityFormData,
} = localEntityDetailEvents;
const { $localEntityData, $localEntityFields, $entityFieldsGroupsStore, $entityFormDataStore } =
    localEntityDetailStores;
const { fetchEntityFieldsGroupsFx, uploadFileFx, hasErrorsFx } = localEntityDetailEffects;
const {
    $entityFunnelStore,
    $relationsStore,
    $relationsSettingsStore,
    $relatedObjectsStore,
    $entityFunnelStageModalStore,
    $dataContractsStore,
} = entityDetailStores;
const {
    fetchEntityDataFx: fetchDataFx,
    createEntityFx,
    updateEntityFieldFx,
    updateEntityFieldsFx,
    updateEntityDataFx,
    updateFieldsGroupFx,
    fetchEntityFunnelStageFx,
    changeEntityStageFx,
    changeGroupsOrderFx,
    fetchAllRelationsFx,
    fetchRelationsFx,
    fetchRelatedObjectsFx,
    deleteEntityFx: deleteEntity,
    bulkUpdateEntitesFx,
    updateEntityFunnelStageFx,
    fetchEntityDataUpdaterFx,
    fetchDataContractsFx,
    saveEntitySettingsFx,
} = entityDetailEffects;

export function useEntityDetailStorage(): IEntityDetailStorageService {
    const relations = useStore($relationsStore);
    const contracts = useStore($dataContractsStore);
    const relationsSettings = useStore($relationsSettingsStore);
    const fields = useStore($localEntityFields);
    const entityData = useStore($localEntityData);
    const entityFormData = useStore($entityFormDataStore);
    const funnel = useStore($entityFunnelStore);
    const relatedObjects = useStore($relatedObjectsStore);
    const title = entityData.title;
    const meta = useStore($metaStore);
    const groupsList = useStore($entityFieldsGroupsStore);
    const hiddenFields = useMemo(() => fields.filter((f) => f.hidden), [fields]);
    const loadingRelations = useStore(fetchAllRelationsFx.pending);
    const loadingRelatedObjects = useStore(fetchRelatedObjectsFx.pending);
    const saveEntityLoading = useStore(saveEntitySettingsFx.pending);
    const loadingObject = useStore(
        combine([fetchMetaFx.pending, fetchFieldsGroupsFx.pending, fetchDataFx.pending], (arr) =>
            arr.reduce((acc, val) => acc || val, false)
        )
    );

    const changeEntityStage = (stage: { id: string; name: string }, entityId: string) => {
        return changeEntityStageFx({ stage: stage, entityId }).then(() => changeStage(stage));
    };

    const updateFields = useCallback((fields: Partial<Field>[]) => {
        updateEntityFieldsFx({ fields });
    }, []);

    const hideField = (field: Field) => {
        updateEntityFieldFx({
            field: { ...field, group: null },
        });
    };

    const showField = (field: Field, fieldGroup: Nullable<string>) => {
        updateEntityFieldFx({
            field: { ...field, group: fieldGroup },
        });
    };

    const fetchData = (code: string, id: string) => {
        return fetchDataFx({ code, id });
    };

    const fetchDataUpdater = (code: string, id: string) => {
        return fetchEntityDataUpdaterFx({ code, id });
    };

    const createEntity = (code: string, entity: EntityData) => {
        return createEntityFx({ code, entity });
    };

    const handleUpdateGroupTitle = (group: FieldsGroup, params?: any) => {
        updateFieldsGroupFx({ groupCode: group.id as string, params });
    };

    const handleCreateGroup = () => {
        createGroup({ tempId: uuid(), entityId: meta.id, order: groupsList?.length });
    };

    const handleDeleteEntity = (code: string, id: string) => {
        return deleteEntity({ code, id });
    };

    const fetchRelations = (code: string, objectId: string, params?: any) => {
        return fetchAllRelationsFx({ code, objectId, params });
    };
    const fetchRelationsSettings = (code: string) => {
        return fetchRelationsFx(code);
    };
    return {
        title,
        meta,
        fields,
        entityData,
        entityFormData,
        saveTitle: syncLocalTitle,
        saveEntityData: syncLocalData,
        saveFields: syncLocalFields,
        updateEntityData: updateLocalEntityData,
        updateEntitiesData: updateEntityDataFx,
        hideField,
        showField,
        fetchData,
        fetchDataUpdater,
        createEntity,
        updateFields,
        resetEntityDetail,
        updateFieldsGroup: handleUpdateGroupTitle,
        funnel,
        fetchFunnel: fetchEntityFunnelStageFx,
        updateFunnel: updateEntityFunnelStageFx,
        changeEntityStage,
        changeGroupsOrder: changeGroupsOrderFx,
        deleteGroup,
        hiddenFields,
        groupsList,
        createGroup: handleCreateGroup,
        deleteEntity: handleDeleteEntity,
        fetchGroups: fetchEntityFieldsGroupsFx,
        bulkUpdateEntites: bulkUpdateEntitesFx,
        updateFunnelStage,
        updateBulkFunnelStage,
        fetchAllRelations: fetchRelations,
        fetchRelationsSettings,
        fetchRelatedObjects: fetchRelatedObjectsFx,
        fetchDataCode: fetchDataContractsFx,
        contracts,
        relationsSettings,
        relations,
        relatedObjects,
        loadingRelations,
        uploadFile: uploadFileFx,
        setErrors: hasErrorsFx,
        copyForm,
        loadingObject,
        resetEntityFormData,
        loadingRelatedObjects,
        saveEntitySettingsFx,
        saveEntityLoading,
    };
}

const { $taskFieldsStore, $taskDataStore, $crmObject } = taskStores;
const { fetchTaskFieldsFx, deleteTaskFx, createTaskFx, fetchTaskDataFx, updateTaskDataFx } = taskEffects;
const { resetTasksDetail, setDefaultCrmObject, clearDefaultCrmObject, updateLocalTaskData } = taskEvents;

export function useTaskDetailStorage(): ITaskDetailStorageService {
    const fields = useStore($taskFieldsStore);
    const taskData = useStore($taskDataStore);
    const defaultCrmObject = useStore($crmObject);

    return {
        defaultCrmObject,
        setDefaultCrmObject,
        clearDefaultCrmObject,
        taskData,
        fields,
        deleteTask: deleteTaskFx,
        createTask: createTaskFx,
        fetchData: fetchTaskDataFx,
        updateTask: updateTaskDataFx,
        fetchFields: fetchTaskFieldsFx,
        resetFields: resetTasksDetail,
        updateTaskLocal: updateLocalTaskData,
    };
}

const { $connectEntityModalStore, $entityListStore, $entityParams } = entityDetailStores;
const { searchEntityFx } = entityDetailEffects;
const {
    openConnectEntityModal,
    closeConnectEntityModal,
    fetch,
    updateFunnelStage,
    updateBulkFunnelStage,
    resetParams,
    updateEntityFunnelStageModal,
} = entityDetailEvents;

export function useConnectEntityModal(): IConnectEntityModalStorageService {
    const { isOpen, type, code, handleSelect, selected, modalTitle } = useStore($connectEntityModalStore);
    const entityList = useStore($entityListStore);
    const params = useStore($entityParams);
    const isSearch = useStore(searchEntityFx.pending);
    const handleSearch = (text: string) => {
        resetParams();
        searchEntityFx({
            code,
            text,
            entity_is_deleted: false,
            is_deleted: false,
            limit: code !== 'department-employees' ? params.limit : 100000,
            offset: 0,
        });
    };

    return {
        open: openConnectEntityModal,
        close: closeConnectEntityModal,
        isOpen,
        type,
        code,
        handler: handleSelect,
        data: entityList,
        selected,
        search: handleSearch,
        fetch,
        params,
        resetParams,
        isSearch,
        modalTitle,
    };
}

const { $employeesStore } = employeesStores;
const { fetchEmployeesFx, updateEmployeeFx } = employeesEffects;

export function useEmployeesStorage(): IEmployeesStorageService {
    const employees = useStore($employeesStore);

    const updateEmployee = (employeeId: string, data: any) => {
        return updateEmployeeFx({ employeeId, data });
    };

    return {
        employees,
        fetchEmployees: fetchEmployeesFx,
        updateEmployee: updateEmployee,
    };
}

const { setCalendarState } = calendarEvents;
const { $calendarState, $tasksWithDeadline, $tasksWithoutDeadline } = calendarStores;
const { fetchTasksWithoutDeadlineFx, fetchTasksFx } = calendarEffects;

export function useCalendarStorage(): ICalendarStorage {
    const calendarState = useStore($calendarState);
    const tasks = useStore($tasksWithDeadline);
    const tasksWithoutDeadline = useStore($tasksWithoutDeadline);
    return {
        tasksWithDeadline: tasks,
        tasksWithoutDeadline: tasksWithoutDeadline,
        calendarState,
        setCalendarState,
        fetchTasks: fetchTasksFx,
        fetchTasksWithoutDeadline: fetchTasksWithoutDeadlineFx,
    };
}

const { $reportDetail } = reportStores;
const { updateReport, resetReport } = reportEvents;
const { createReportFx, fetchDetailReportFx, saveReportFx } = reportEffects;

export function useReportDetailStorage(): IReportDetailStorage {
    const data = useStore($reportDetail);
    const loadingEntity = useStore(fetchMetaFx.pending);
    const isReportCreating = useStore(createReportFx.pending);
    const isReportSaving = useStore(saveReportFx.pending);

    const handleSaveReport = (id: string, data: any) => {
        return saveReportFx({ id, data });
    };

    return {
        data,
        updateReport,
        loadingEntity,
        saveReport: handleSaveReport,
        createReport: createReportFx,
        isDataFetching: isReportCreating || isReportSaving,
        fetchDetailReport: fetchDetailReportFx,
        resetReport,
    };
}

const { $documentsStore, $documentTemplatesStore } = documentsStore;
const {
    fetchDocumentsFx,
    createDocumentTypeFx,
    updateDocumentTypeFx,
    fetchFunnelListFx,
    fetchNumeratorsFx,
    fetchTemplateDocumentsFx,
} = documentEffects;
export function useDocumentsStorage(): IDocumentsStorage {
    const documents = useStore($documentsStore);
    const documentTemplates = useStore($documentTemplatesStore);
    const isLoadingCreated = useStore(createDocumentTypeFx.pending);
    const loadingData = useStore(fetchDocumentsFx.pending);
    const loadingTemplates = useStore(fetchTemplateDocumentsFx.pending);
    const updateDocumentTypeStatus = async (documentIds: string[]) => {
        const filterDocuments = documents.results.filter((i) => documentIds.find((d) => i.id === d));
        for (const document of filterDocuments) {
            await updateDocumentTypeFx({
                id: document.id,
                name: document.name,
            });
        }
    };
    return {
        documents,
        documentTemplates,
        fetchDocuments: fetchDocumentsFx,
        fetchTemplateDocuments: fetchTemplateDocumentsFx,
        createDocument: createDocumentTypeFx,
        updateTypeDocument: updateDocumentTypeStatus,
        fetchFunnels: fetchFunnelListFx,
        fetchNumerators: fetchNumeratorsFx,
        loadingTemplates,
        isLoadingCreated,
        loadingData,
    };
}

const { $distributionsStore } = distributionsStore;
const { fetchDistributionsFx } = distributionsEffects;
export function useDistributionStorage() {
    const distributions = useStore($distributionsStore);
    return {
        distributions,
        fetchDistributions: fetchDistributionsFx,
    };
}
