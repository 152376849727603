import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const EditCol = ({ value, id }: { value: string; id?: string }) => {
    const navigate = useNavigate();
    return (
        <Grid display="flex" alignItems="center">
            <IconButton onClick={() => navigate(`/template-documents?edit=${id}`)}>
                <EditIcon />
            </IconButton>
            <div>{value}</div>
        </Grid>
    );
};

export default EditCol;
