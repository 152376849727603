import { useCallback, useState } from 'react';
import { Avatar, Button, Divider, FormHelperText, Grid, Typography } from '@mui/material';
import { CropImage, Modal } from 'shared/ui';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { useDropzone } from 'react-dropzone';

import { useCropImage } from 'shared/hooks/useCropImage';
import { useTranslation } from 'react-i18next';

const ChangeAvatar = ({ open, user, onUpdate, onClose }: any) => {
    const { t } = useTranslation();
    const addCropPhoto = useCropImage(320, 320);
    const [step, setStep] = useState<number>(1);
    const [fileSizeError, setFileSizeError] = useState(false);

    const update = (avatar: any) => {
        onUpdate({ ...user, avatar });
    };

    const onDrop = useCallback((files: File[]) => {
        const file = files[0];
        if (file.size / (1024 * 1024) <= 10) {
            addCropPhoto.onSelectFile(files[0]);
            setStep(2);
        } else {
            setFileSizeError(true);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <Modal
            open={open}
            title={step === 1 ? user.first_name : t('changeProfilePhoto')}
            width={633}
            onClose={onClose}
        >
            {step === 1 ? (
                <>
                    <Grid mb="16px" mt="24px" px="24px">
                        <Avatar
                            variant="rounded"
                            alt={user.first_name}
                            src={user?.avatar}
                            sx={{ width: 204, height: 204, margin: 'auto' }}
                        />
                        <Grid
                            {...getRootProps()}
                            container
                            justifyContent="center"
                            alignItems="center"
                            py="24px"
                            mt="24px"
                            gap={'12px'}
                            sx={{
                                background: '#FCFBFC',
                                border: '1px dashed rgba(0, 0, 0, 0.16)',
                                borderRadius: '8px',
                            }}
                        >
                            <Grid container justifyContent="center" alignItems="center" gap={'12px'}>
                                <FileDownloadRoundedIcon color="primary" />
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <Grid sx={{ maxWidth: '300px' }}>
                                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>
                                            {t('dropFileText')}
                                        </Typography>
                                        <Typography
                                            component={'span'}
                                            sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}
                                        >
                                            {'Или '}
                                        </Typography>
                                        <Typography
                                            component={'span'}
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: '#5846FA',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {' '}
                                            загрузите с компьютера
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <FormHelperText>
                                {t('uploadFileDesc', {
                                    size: '10 МБ',
                                    format: '.PNG, .JPEG',
                                })}
                            </FormHelperText>
                        </Grid>
                        {fileSizeError && (
                            <FormHelperText error sx={{ textAlign: 'center', mt: '12px' }}>
                                {t('uploadPhotoError')}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Divider />
                    <Grid container justifyContent={'flex-end'} mt="10px" sx={{ px: '24px' }}>
                        <Button variant="outlined" onClick={onClose}>
                            {t('cancel')}
                        </Button>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid height={'600px'} px="24px">
                        <CropImage {...addCropPhoto} />
                    </Grid>
                    <Divider />
                    <Grid container justifyContent={'flex-end'} mt="10px" sx={{ px: '24px' }}>
                        <Button
                            variant="contained"
                            sx={{ mr: '8px' }}
                            onClick={() => {
                                addCropPhoto.cropImageNow(update);
                                onClose();
                            }}
                        >
                            {t('savePhoto')}
                        </Button>
                        <Button variant="outlined" onClick={onClose}>
                            {t('cancel')}
                        </Button>
                    </Grid>
                </>
            )}
        </Modal>
    );
};

export default ChangeAvatar;
