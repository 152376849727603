import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DepartmentsMap } from 'widgets';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Autocomplete, Box, Chip, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useDepartmentsStorage, useEmployeesStorage, useUsersStorage } from 'services/StorageAdapter';
import { Nullable } from 'shared/types';
import { Button, DefaultDrawer, EditableTitle, FormField, Input, TabPanel } from 'shared/ui';
import { DepartmentType, EmployeeType } from 'domain/employee';
import Card from 'shared/ui/Card/Card';
import { useFormik } from 'formik';
import ConnectField from 'shared/ui/ConnectField/ConnectField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDndScrolling } from 'react-dnd-scrolling';
import { LoadingButton } from '@mui/lab';
import { normalize } from 'shared/helpers/normalize';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import DepartmentEmployeesTab from 'widgets/DepartmentEmployeesTab/DepartmentEmployeesTab';
import { UserProfile } from '../../widgets/Profile';
import { useNotificationTitle } from '../../widgets/NotificationBar';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export type DepartmentNode = {
    id: string;
    name: string;
    parent: Nullable<string>;
    head: Nullable<EmployeeType>;
    headId: Nullable<string>;
    children: Array<DepartmentNode>;
    employees: Set<EmployeeType>;
};

const makeTree = (
    departments: DepartmentType[],
    employees: EmployeeType[]
): [DepartmentNode, Map<string, DepartmentNode>, Map<string, EmployeeType>] => {
    const nodesMap = new Map<string, DepartmentNode>(
        departments.map((department: any) => {
            return [
                department.id,
                {
                    id: department.id,
                    parent: department.parent,
                    head: null,
                    headId: department.head,
                    name: department.name,
                    children: [],
                    employees: new Set(),
                },
            ];
        })
    );
    const employeesMap = new Map<string, EmployeeType>(
        employees.map((employee) => [employee.user.id, employee])
    );
    for (const node of nodesMap.values()) {
        if (node.headId) {
            const head = employeesMap.get(node.headId)!;
            node.head = head;
        }

        if (!node.parent) {
            continue;
        }
        const parent = nodesMap.get(node.parent)!;

        parent.children = [...(parent.children || []), node];
    }
    for (const employee of employees) {
        const department = nodesMap.get(employee.department.id)!;
        department.employees.add(employee);
    }
    return [[...nodesMap.values()].filter((item) => !item.parent)[0], nodesMap, employeesMap];
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type initialValuesType = {
    role: null | ConnectedObjectType;
    parent: string;
    name: string;
    head: Nullable<ConnectedObjectType>;
    employees: ConnectedObjectType[];
    child: Array<any>;
};

const initialValuesDefault = (t: TFunction): initialValuesType => {
    return {
        role: null,
        parent: '934d6b56-60ff-4070-9e50-c0bfad239ba5',
        name: t('newDivision'),
        head: null,
        child: [],
        employees: [],
    };
};

export const SelectUserIdContext = createContext({
    selectedUserId: '',
    handleSelectUserId: (_id: string) => {},
});
const DepartmentsControl = () => {
    const { t } = useTranslation();
    const { t: departmentsT } = useTranslation('departmentsControl');
    const [params] = useSearchParams();
    const isCreated = Boolean(params.get('department_id'));
    const [tab, setTab] = React.useState(0);
    const [initialValues, setInitialValues] = useState(initialValuesDefault(t));
    const { setFieldValue, values, resetForm } = useFormik({
        initialValues,
        onSubmit: (_values) => {},
        enableReinitialize: true,
    });
    const {
        fetchDepartments,
        departments,
        createDepartment,
        getDepartment,
        updateDepartment,
        department,
        deleteDepartment,
        clearDepartment,
    } = useDepartmentsStorage();

    const [departmentsTree, setDepartmentsTree] = useState<Nullable<DepartmentNode>>(null);
    const [departmentsMap, setDepartmentsMap] = useState<Nullable<Map<string, DepartmentNode>>>(null);
    const [isEdited, setIsEdited] = useState(false);
    const [isCardOpen, setIsCardOpen] = useState(false);
    const { fetchEmployees, employees } = useEmployeesStorage();
    const { me } = useUsersStorage();
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    useDndScrolling(containerRef, {});

    useNotificationTitle(t('department'));

    useEffect(() => {
        if (isCardOpen && !isCreated) {
            clearDepartment();
        }
    }, [isCardOpen, isCreated]);

    useEffect(() => {
        fetchDepartments({ limit: 100000 });
        fetchEmployees({ limit: 100000 });
    }, []);

    const handleOpenCard = useCallback(() => {
        setIsCardOpen(true);
    }, []);

    useEffect(() => {
        if (params.has('department_id')) {
            clearDepartment();
            getDepartment(params.get('department_id')!);
            handleOpenCard();
        }
    }, [params]);
    useEffect(() => {
        if (department) {
            const { head } = department;
            if (head && typeof head === 'object' && !head.name) {
                head.name = `${head.first_name} ${head.last_name}`;
            }
            if (department.parent === '' && departmentsTree) {
                department.parent = departmentsTree.id;
            }
            setInitialValues({ ...initialValues, ...department, head });
        } else {
            setInitialValues(initialValuesDefault(t));
        }
    }, [department, departmentsTree]);

    useEffect(() => {
        if (departments.results.length > 0) {
            const [tree, departmentsMap] = makeTree(departments.results, employees);
            setDepartmentsTree(tree);
            setDepartmentsMap(departmentsMap);
        }
    }, [departments, employees]);

    const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const setFieldValueWrapper = (
        ...args: [field: string, value: any, shouldValidate?: boolean | undefined]
    ) => {
        setIsEdited(true);
        setFieldValue(...args);
    };

    const handleCloseCard = () => {
        setIsCardOpen(false);
        setInitialValues(initialValuesDefault(t));
        navigate('./');
    };

    const handleCancelCreate = () => {
        handleCloseCard();
        resetForm();
    };

    const handleCancelEdit = () => {
        resetForm();
        setIsEdited(false);
    };

    const handleCreateDepartment = () => {
        setIsEdited(false);
        setIsLoading(true);
        const fixEmployees = values.employees.map((e) => e.user);
        const normalizedValues = normalize({ ...values, employees: fixEmployees });
        createDepartment(normalizedValues)
            .then(() => {
                setInitialValues(initialValuesDefault(t));
            })
            .finally(() => {
                setIsLoading(false);
                setIsCardOpen(false);
                setFieldValueWrapper('employees', []);
            });
    };

    const handleSaveDepartment = () => {
        const fixEmployees = values.employees.map((e) => e.user);
        const normalizedValues: any = normalize({ ...values, employees: fixEmployees });
        if (normalizedValues.parent === null) {
            delete normalizedValues.parent;
        }
        updateDepartment(params.get('department_id')!, {
            ...normalizedValues,
        });
        setIsEdited(false);
    };

    const handleDeleteDepartment = () => {
        setIsCardOpen(false);
        navigate('./');
        deleteDepartment(params.get('department_id') as string);
    };

    const [selectedUserId, setSelectedUserId] = useState('');
    const [profileDrawerOpen, setProfileDrawerOpen] = useState<boolean>(false);
    const handleSelectUserId = (id: string) => {
        setProfileDrawerOpen(true);
        setSelectedUserId(id);
    };
    return (
        <SelectUserIdContext.Provider
            value={{
                selectedUserId,
                handleSelectUserId,
            }}
        >
            {selectedUserId ? (
                <UserProfile
                    open={profileDrawerOpen}
                    handleClose={() => setProfileDrawerOpen(false)}
                    userId={selectedUserId}
                />
            ) : null}
            <Typography variant={'h5'}>{departmentsT('title')}</Typography>
            <Box my={'20px'}>
                <Divider />
            </Box>
            {me.is_admin ? (
                <Grid container gap={'24px'} flexWrap={'nowrap'} mb={'30px'} justifyContent={'flex-end'}>
                    {/*<Search />*/}
                    <Button
                        sx={{
                            whiteSpace: 'nowrap',
                            flexShrink: 0,
                        }}
                        size={'small'}
                        variant={'contained'}
                        startIcon={<AddCircleIcon />}
                        onClick={handleOpenCard}
                    >
                        {departmentsT('createDivision')}
                    </Button>
                </Grid>
            ) : null}
            <Box
                width="100%"
                height="900px"
                overflow="auto"
                ref={containerRef}
                sx={{
                    border: '1px solid rgba(0, 0, 0, 0.16)',
                    borderRadius: '12px',
                    padding: '24px',
                    height: 'calc(100vh - 132px)',
                    display: 'grid',
                }}
            >
                {departmentsTree && departmentsMap && (
                    <DepartmentsMap
                        openCard={handleOpenCard}
                        map={departmentsMap}
                        setMap={setDepartmentsMap}
                        tree={departmentsTree}
                    />
                )}
            </Box>
            <DefaultDrawer onClose={handleCloseCard} open={isCardOpen}>
                <Box
                    sx={{
                        width: '600px',
                        padding: '25px 29px',
                    }}
                >
                    <Grid container flexWrap={'nowrap'} justifyContent={'space-between'}>
                        <EditableTitle
                            editable={me.is_admin}
                            title={values['name']}
                            onChange={(value) => setFieldValueWrapper('name', value)}
                        />
                        {isCreated && me.is_admin && (
                            <Button
                                onClick={handleDeleteDepartment}
                                color={'error'}
                                startIcon={<DeleteRoundedIcon />}
                            >
                                {t('delete')}
                            </Button>
                        )}
                    </Grid>
                    <Box py={'24px'}>
                        <Divider />
                    </Box>
                    <Grid container flexDirection={'column'} gap={'24px'}>
                        <Tabs variant={'fullWidth'} value={tab} onChange={handleChangeTab}>
                            <Tab
                                label={t('basicSettings')}
                                sx={{
                                    minHeight: '50px',
                                }}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={t('employees')}
                                sx={{
                                    minHeight: '50px',
                                }}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                        <TabPanel value={tab} index={0}>
                            <Grid container flexDirection={'column'} gap={'24px'}>
                                <Card>
                                    <Card.Head title={t('basicSettings')} />
                                    <ConnectField
                                        items={values.head}
                                        label={departmentsT('head')}
                                        setValue={(field, value, shouldValidate) => {
                                            if (value) {
                                                value.user.name = `${value.user.first_name} ${value.user.last_name}`;
                                                setFieldValueWrapper(field, value.user, shouldValidate);
                                            } else {
                                                setFieldValueWrapper(field, value, shouldValidate);
                                            }
                                        }}
                                        value={values['head']}
                                        field={'head'}
                                        type={'fk_employee'}
                                        code={'department-employees'}
                                        isEdit={me.is_admin}
                                    />
                                    <ConnectField
                                        items={values.role}
                                        label={departmentsT('role')}
                                        setValue={setFieldValueWrapper}
                                        value={values['role']}
                                        field={'role'}
                                        type={'fk_department'}
                                        code={'roles'}
                                        isEdit={me.is_admin}
                                    />
                                </Card>
                                <Card>
                                    <Card.Head title={departmentsT('hierarchy')} />
                                    <FormField label={departmentsT('parent')}>
                                        <Autocomplete
                                            disabled={
                                                params.get('department_id') === departmentsTree?.id ||
                                                !me.is_admin
                                            }
                                            renderInput={Input}
                                            value={
                                                departmentsMap?.get(values['parent'])?.name ||
                                                //@ts-ignore
                                                values['parent']?.name ||
                                                ''
                                            }
                                            onChange={(_event, newValue: any) =>
                                                setFieldValueWrapper('parent', newValue.id)
                                            }
                                            options={departments.results.map((d) => ({
                                                label: d.name,
                                                ...d,
                                            }))}
                                            fullWidth
                                        />
                                    </FormField>
                                    <FormField label={departmentsT('child')}>
                                        {values.child.length > 0 ? (
                                            <Grid container gap={'8px'}>
                                                {values.child.map(({ name, id }) => {
                                                    return (
                                                        <Chip key={id} label={name} variant={'outlined'} />
                                                    );
                                                })}
                                            </Grid>
                                        ) : (
                                            <Typography variant={'body2'}>
                                                {departmentsT('childEmpty')}
                                            </Typography>
                                        )}
                                    </FormField>
                                </Card>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <DepartmentEmployeesTab
                                setValue={setFieldValueWrapper}
                                employees={values.employees}
                            />
                        </TabPanel>
                        <Divider />
                        <Grid container justifyContent={'flex-end'} mt={'12px'} gap={'8px'}>
                            {(!isCreated || (isCreated && isEdited)) && (
                                <>
                                    <LoadingButton
                                        size={'small'}
                                        loading={isLoading}
                                        onClick={() => {
                                            if (!isCreated) {
                                                handleCreateDepartment();
                                            } else if (isCreated && isEdited) {
                                                handleSaveDepartment();
                                            }
                                        }}
                                        variant={'contained'}
                                    >
                                        {isCreated && isEdited && departmentsT('save')}
                                        {!isCreated && departmentsT('createDivision')}
                                    </LoadingButton>
                                    <Button
                                        size={'small'}
                                        onClick={() => {
                                            if (!isCreated) {
                                                handleCancelCreate();
                                            } else if (isCreated && isEdited) {
                                                handleCancelEdit();
                                            }
                                        }}
                                        variant={'outlined'}
                                    >
                                        {isCreated && isEdited && t('cancelChange')}
                                        {!isCreated && t('cancelCreate')}
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </DefaultDrawer>
        </SelectUserIdContext.Provider>
    );
};

export default DepartmentsControl;
