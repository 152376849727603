import React from 'react';
import { Button, Grid, IconButton, Menu, Typography } from '@mui/material';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import TableDropdown, { ITableDropdown } from './TableDropdown';
import { useTranslation } from 'react-i18next';

interface IProps {
    onClear?: () => void;
    onEdit?: () => void;
    count: number;
    selected?: string[];
    buttons?: ITableDropdown[];
}

export default function TableBottom({ onClear, onEdit, count, selected, buttons }: IProps) {
    const { t } = useTranslation();
    const [actionEl, setActionEl] = React.useState<HTMLButtonElement | null>(null);
    const action = Boolean(actionEl);
    const handleOpenAction = (event: React.MouseEvent<HTMLButtonElement>) => setActionEl(event.currentTarget);
    const handleCloseAction = () => setActionEl(null);
    return (
        <Grid
            container
            gap={'16px'}
            py={'19px'}
            px={3}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <Grid container gap={'16px'} alignItems={'center'} width={'auto'}>
                <Grid
                    container
                    gap={'12px'}
                    alignItems={'center'}
                    width={'auto'}
                    borderRight={'1px solid rgba(0, 0, 0, 0.12)'}
                    pr={3}
                >
                    <Typography variant={'h6'} fontSize={14}>
                        {t('chosenCount', { selected: count })}
                    </Typography>
                    <IconButton size="small" onClick={onClear}>
                        <CloseIcon fontSize={'inherit'} />
                    </IconButton>
                </Grid>
                {buttons?.find((item) => item.type === 'edit')?.type && (
                    <Button sx={{ color: 'rgba(0, 0, 0, 0.54)' }} endIcon={<EditIcon />} onClick={onEdit}>
                        {buttons?.find((item) => item.type === 'edit')?.title || t('edit')}
                    </Button>
                )}
                {!!buttons?.length &&
                    buttons.map(
                        (item, key) =>
                            item.type !== 'edit' &&
                            item.type !== 'delete' &&
                            item.type !== 'save' && (
                                <>
                                    <Button
                                        sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                        endIcon={item?.sub_menu?.length ? <ArrowDropUpRoundedIcon /> : <></>}
                                        onClick={(event) =>
                                            item?.sub_menu?.length
                                                ? handleOpenAction(event)
                                                : item?.func?.(selected)
                                        }
                                        key={key}
                                    >
                                        {item.title}
                                    </Button>
                                    {!!item?.sub_menu?.length && (
                                        <Menu
                                            anchorEl={actionEl}
                                            open={!!action}
                                            onClose={() => {
                                                item?.func?.(selected);
                                                handleCloseAction();
                                            }}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <TableDropdown
                                                menu={item.sub_menu}
                                                item={selected}
                                                onClose={handleCloseAction}
                                            />
                                        </Menu>
                                    )}
                                </>
                            )
                    )}
            </Grid>
            <Grid container alignItems={'center'} width={'auto'}>
                {buttons?.find((item) => item.type === 'delete')?.type && (
                    <Button
                        variant="text"
                        color={'error'}
                        endIcon={
                            buttons?.find((item) => item.title === 'Аннулировать') ? <></> : <DeleteIcon />
                        }
                        onClick={() => buttons?.find((item) => item.type === 'delete')?.func?.(selected)}
                    >
                        {buttons?.find((item) => item.type === 'delete')?.title || t('delete')}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}
