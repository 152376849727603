import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
    AccordionSummaryProps,
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    RadioGroup,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Alert, Button, Input, Modal, Radio } from 'shared/ui';
import { useConnectEntityModal } from 'services/StorageAdapter';
import SearchIcon from '@mui/icons-material/Search';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import useDebounce from 'shared/hooks/useDebounce';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { Nullable } from 'shared/types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { currentPlaceholder } from './lib/currentPlaceholder';
import { MOBILE_QUERY_WIDTH } from 'shared/consts';
import { useTranslation } from 'react-i18next';

type EmployeeType = {
    id: string;
    user: {
        id: string;
        first_name: string;
        last_name: string;
    };
    department: {
        id: string;
        name: string;
    };
};

type DepartmentType = {
    id: string;
    name: string;
    employees: EmployeeType[];
};

const ConnectEntityModal = () => {
    const { t } = useTranslation();
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const {
        isOpen,
        close,
        handler,
        type,
        code,
        data,
        selected,
        fetch,
        params,
        resetParams,
        search,
        isSearch,
        modalTitle,
    } = useConnectEntityModal();
    const [error, setError] = useState('');
    const [searchText, setSearchText] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(0);
    const lastCode = useRef<string | null>(null);
    const groupedEmployees: Record<string, DepartmentType> = useMemo(() => {
        if (code === 'department-employees') {
            return data.reduce((acc: Record<string, DepartmentType>, employee: EmployeeType) => {
                return {
                    ...acc,
                    [employee.department?.id]: {
                        id: employee.department?.id,
                        name: employee.department?.name,
                        employees: [...(acc[employee.department?.id]?.employees || []), employee],
                    },
                };
            }, {});
        }
        return {};
    }, [data, code]);

    const debouncedSearch = useDebounce(searchText, 500);
    const mobileQuery = useMediaQuery(MOBILE_QUERY_WIDTH);

    const initialValue: { select: Nullable<ConnectedObjectType | ConnectedObjectType[]> } = useMemo(
        () => ({
            select: selected ? selected : null,
        }),
        [type, selected]
    );

    useEffect(() => {
        if (!isOpen || lastCode.current === code) {
            return;
        }
        lastCode.current = code;
        resetParams();
        fetch({
            limit: 'department-employees' === code ? 1000000 : 20,
            offset: 0,
        });
    }, [isOpen, code]);

    useEffect(() => {
        if (code) {
            search(debouncedSearch);
        }
    }, [debouncedSearch]);

    // useEffect(() => {
    //     if (lastCode.current !== code) {
    //         resetParams();
    //     }
    // }, [code]);

    useEffect(() => {
        if (!isOpen) {
            setSearchText('');
        }
        return () => {
            setSearchText('');
        };
    }, [isOpen]);

    // useEffect(() => {
    //     if (!isOpen) {
    //         return;
    //     }
    //     if (debouncedSearch) {
    //         search(debouncedSearch);
    //     }
    // }, [isOpen, debouncedSearch, code]);

    const fetchNext = () => {
        const { limit, offset, search } = params;
        fetch({ limit, offset, search });
    };

    const handleSubmit = (values: any) => {
        handler(values.select);
        close();
        if (debouncedSearch) {
            setSearchText('');
        }
    };

    const handleSubmitClick = (_: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            const value = formRef.current.values.select;
            if (value?.length === 0) {
                setError(t('selectValueToContinue'));
            } else {
                setError('');
                formRef.current.submitForm();
            }
        }
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    return (
        <Modal
            open={isOpen}
            onClose={close}
            width={'633px'}
            title={modalTitle || t('bind')}
            style={{
                ...(mobileQuery
                    ? {
                          top: 'unset',
                          bottom: 0,
                          transform: 'translateX(-50%)',
                      }
                    : {}),
                overflowY: 'hidden',
                maxHeight: '500px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Formik
                innerRef={formRef}
                enableReinitialize
                initialValues={initialValue}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => {
                    return (
                        <Form style={{ height: '100%' }}>
                            <Grid container flexDirection={'column'} height={'100%'} flexWrap={'nowrap'}>
                                <Grid p={'24px'}>
                                    <Input
                                        onChange={handleSearch}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <SearchIcon color={'secondary'} fontSize={'small'} />
                                            ),
                                        }}
                                        placeholder={currentPlaceholder[type] || t('search')}
                                    />
                                </Grid>
                                <Divider />
                                <Grid
                                    sx={{
                                        overflowY: 'hidden',
                                        flexGrow: 1,
                                    }}
                                    pt={'24px'}
                                    pl={'24px'}
                                >
                                    {!isSearch ? (
                                        <>
                                            {type === 'fk_employee' ||
                                            type === 'm2m_employee' ||
                                            type === 'system_m2m' ||
                                            type === 'system_fk' ? (
                                                <Grid
                                                    container
                                                    flexWrap={'nowrap'}
                                                    gap={'12px'}
                                                    height={'100%'}
                                                >
                                                    {!mobileQuery && (
                                                        <Grid
                                                            container
                                                            width={'auto'}
                                                            flexDirection={'column'}
                                                            gap={'8px'}
                                                        >
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setSelectedGroup(0);
                                                                }}
                                                                selected={selectedGroup === 0}
                                                            >
                                                                <Typography>{t('allUsers')}</Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setSelectedGroup(1);
                                                                }}
                                                                selected={selectedGroup === 1}
                                                            >
                                                                <Typography>
                                                                    {t('divisionsAndDepartments')}
                                                                </Typography>
                                                            </MenuItem>
                                                        </Grid>
                                                    )}
                                                    <Grid container height={'100%'} flexDirection={'column'}>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                flexGrow: 1,
                                                                overflowY: 'auto',
                                                            }}
                                                            id={'scrollableTarget'}
                                                        >
                                                            <InfiniteScroll
                                                                next={fetchNext}
                                                                hasMore={params.hasNext}
                                                                loader={
                                                                    <Grid
                                                                        container
                                                                        justifyContent={'center'}
                                                                        alignItems={'center'}
                                                                        height={'80px'}
                                                                    >
                                                                        <CircularProgress />
                                                                    </Grid>
                                                                }
                                                                dataLength={data.length}
                                                                style={{
                                                                    width: '100%',
                                                                    overflowX: 'hidden',
                                                                    overflowY: 'hidden',
                                                                }}
                                                                scrollableTarget={'scrollableTarget'}
                                                            >
                                                                {type === 'fk_employee' ||
                                                                type === 'system_fk' ? (
                                                                    <RadioGroup
                                                                        style={{
                                                                            paddingLeft: mobileQuery
                                                                                ? '0'
                                                                                : '16px',
                                                                            paddingRight: mobileQuery
                                                                                ? '16px'
                                                                                : '0',
                                                                        }}
                                                                        value={values.select?.id || null}
                                                                        onChange={(e) =>
                                                                            setFieldValue(
                                                                                'select',
                                                                                JSON.parse(
                                                                                    e.target.dataset
                                                                                        .data as string
                                                                                )
                                                                            )
                                                                        }
                                                                    >
                                                                        {selectedGroup === 0 ? (
                                                                            <>
                                                                                {data.map(
                                                                                    ({
                                                                                        id,
                                                                                        user,
                                                                                    }: EmployeeType) => {
                                                                                        const {
                                                                                            first_name,
                                                                                            last_name,
                                                                                        } = user;
                                                                                        return (
                                                                                            <>
                                                                                                <Radio
                                                                                                    sxLabel={{
                                                                                                        py: mobileQuery
                                                                                                            ? '9px'
                                                                                                            : '0',
                                                                                                    }}
                                                                                                    size={
                                                                                                        'small'
                                                                                                    }
                                                                                                    key={id}
                                                                                                    value={id}
                                                                                                    inputProps={{
                                                                                                        // @ts-ignore
                                                                                                        'data-data':
                                                                                                            JSON.stringify(
                                                                                                                {
                                                                                                                    id,
                                                                                                                    name: `${first_name} ${last_name}`,
                                                                                                                    user,
                                                                                                                }
                                                                                                            ),
                                                                                                    }}
                                                                                                    label={`${first_name} ${last_name}`}
                                                                                                />
                                                                                                {mobileQuery && (
                                                                                                    <Divider />
                                                                                                )}
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {Object.keys(
                                                                                    groupedEmployees
                                                                                ).map((key) => {
                                                                                    const obj =
                                                                                        groupedEmployees[key];

                                                                                    return (
                                                                                        <>
                                                                                            <ConnectAccordion
                                                                                                key={key}
                                                                                                label={
                                                                                                    obj.name
                                                                                                }
                                                                                                setValue={
                                                                                                    setFieldValue
                                                                                                }
                                                                                                values={
                                                                                                    values.select
                                                                                                }
                                                                                                data={
                                                                                                    obj.employees
                                                                                                }
                                                                                            />
                                                                                            <Divider />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                        )}
                                                                    </RadioGroup>
                                                                ) : (
                                                                    <FormGroup>
                                                                        {selectedGroup === 0 ? (
                                                                            <>
                                                                                {data.map(
                                                                                    ({
                                                                                        id,
                                                                                        user,
                                                                                    }: EmployeeType) => {
                                                                                        const {
                                                                                            first_name,
                                                                                            last_name,
                                                                                        } = user;
                                                                                        return (
                                                                                            <ConnectCheckbox
                                                                                                key={id}
                                                                                                value={id}
                                                                                                data={{
                                                                                                    id,
                                                                                                    name: `${first_name} ${last_name}`,
                                                                                                    user,
                                                                                                }}
                                                                                                values={
                                                                                                    values.select
                                                                                                }
                                                                                                setValue={
                                                                                                    setFieldValue
                                                                                                }
                                                                                                label={`${first_name} ${last_name}`}
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {Object.keys(
                                                                                    groupedEmployees
                                                                                ).map((key) => {
                                                                                    const obj =
                                                                                        groupedEmployees[key];
                                                                                    return (
                                                                                        <ConnectAccordion
                                                                                            key={key}
                                                                                            label={obj.name}
                                                                                            setValue={
                                                                                                setFieldValue
                                                                                            }
                                                                                            values={
                                                                                                values.select
                                                                                            }
                                                                                            data={
                                                                                                obj.employees
                                                                                            }
                                                                                        />
                                                                                    );
                                                                                })}
                                                                            </>
                                                                        )}
                                                                    </FormGroup>
                                                                )}
                                                            </InfiniteScroll>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ) : type === 'fk' ||
                                              type === 'uuid' ||
                                              type === 'fk_department' ? (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        flexGrow: 1,
                                                        maxHeight: '100%',
                                                        overflowY: 'auto',
                                                    }}
                                                    id={'scrollableTarget'}
                                                >
                                                    <InfiniteScroll
                                                        next={fetchNext}
                                                        hasMore={params.hasNext}
                                                        loader={
                                                            <Grid
                                                                container
                                                                justifyContent={'center'}
                                                                alignItems={'center'}
                                                                height={'80px'}
                                                            >
                                                                <CircularProgress />
                                                            </Grid>
                                                        }
                                                        dataLength={data.length}
                                                        style={{
                                                            width: '100%',
                                                            overflowX: 'hidden',
                                                        }}
                                                        scrollableTarget={'scrollableTarget'}
                                                    >
                                                        <RadioGroup
                                                            style={{
                                                                paddingLeft: mobileQuery ? '0' : '16px',
                                                                paddingRight: mobileQuery ? '16px' : '0',
                                                            }}
                                                            value={values.select?.id}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    'select',
                                                                    JSON.parse(
                                                                        e.target.dataset.data as string
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {data.map(
                                                                ({
                                                                    id,
                                                                    title,
                                                                    name,
                                                                    entity,
                                                                }: {
                                                                    id: string;
                                                                    title: string;
                                                                    name: string;
                                                                    entity?: string;
                                                                }) => {
                                                                    return (
                                                                        <>
                                                                            <Radio
                                                                                sxLabel={{
                                                                                    py: mobileQuery
                                                                                        ? '9px'
                                                                                        : '0',
                                                                                }}
                                                                                size={'small'}
                                                                                key={id}
                                                                                value={id}
                                                                                inputProps={{
                                                                                    // @ts-ignore
                                                                                    'data-data':
                                                                                        JSON.stringify({
                                                                                            id,
                                                                                            name:
                                                                                                title || name,
                                                                                            entity,
                                                                                        }),
                                                                                }}
                                                                                label={title || name || id}
                                                                            />
                                                                            {mobileQuery && <Divider />}
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </RadioGroup>
                                                    </InfiniteScroll>
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        flexGrow: 1,
                                                        maxHeight: '100%',
                                                        overflowY: 'auto',
                                                    }}
                                                    id={'scrollableTarget'}
                                                >
                                                    <InfiniteScroll
                                                        next={fetchNext}
                                                        hasMore={params.hasNext}
                                                        loader={
                                                            <Grid
                                                                container
                                                                justifyContent={'center'}
                                                                alignItems={'center'}
                                                                height={'80px'}
                                                            >
                                                                <CircularProgress />
                                                            </Grid>
                                                        }
                                                        dataLength={data.length}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        scrollableTarget={'scrollableTarget'}
                                                    >
                                                        <FormGroup>
                                                            {data.map(
                                                                ({
                                                                    id,
                                                                    title,
                                                                    name,
                                                                    entity,
                                                                }: {
                                                                    id: string;
                                                                    title: string;
                                                                    name: string;
                                                                    entity: string;
                                                                }) => {
                                                                    return (
                                                                        <>
                                                                            <ConnectCheckbox
                                                                                key={id}
                                                                                value={id}
                                                                                data={{
                                                                                    id,
                                                                                    name: name || title,
                                                                                    entity,
                                                                                }}
                                                                                values={values.select}
                                                                                setValue={setFieldValue}
                                                                                label={title || name || id}
                                                                            />
                                                                            {mobileQuery && <Divider />}
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </FormGroup>
                                                    </InfiniteScroll>
                                                </Box>
                                            )}
                                        </>
                                    ) : (
                                        <Grid
                                            container
                                            height={'100%'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    )}
                                </Grid>
                                {error && (
                                    <Alert sx={{ borderRadius: 0 }} variant={'standard'} type={'warning'}>
                                        <Typography variant={'body2'}>
                                            {t('selectValueToContinue')}
                                        </Typography>
                                    </Alert>
                                )}
                                <Divider />
                                <Grid
                                    px={'8px'}
                                    pt={'16px'}
                                    container
                                    gap={'8px'}
                                    justifyContent={mobileQuery ? 'center' : 'flex-end'}
                                    flexDirection={mobileQuery ? 'row-reverse' : 'row'}
                                >
                                    <Button
                                        sx={{
                                            flexGrow: mobileQuery ? 1 : 0,
                                        }}
                                        onClick={handleSubmitClick}
                                        variant={'contained'}
                                    >
                                        {t('add')}
                                    </Button>
                                    <Button
                                        sx={{
                                            flexGrow: mobileQuery ? 1 : 0,
                                        }}
                                        onClick={close}
                                        variant={'outlined'}
                                    >
                                        {t('cancel')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    border: `none`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

type ConnectCheckboxProps = {
    values: ConnectedObjectType[];
    value: string;
    setValue: FormikProps<FormikValues>['setFieldValue'];
    label: string;
    data: ConnectedObjectType;
};

const ConnectCheckbox = ({ values, value, setValue, label, data }: ConnectCheckboxProps) => {
    return (
        <FormControlLabel
            sx={{
                ml: 0,
            }}
            control={
                <Checkbox
                    value={value}
                    inputProps={{
                        // @ts-ignore
                        'data-data': JSON.stringify(data),
                    }}
                    checked={!!values?.some((obj) => obj.id === value)}
                    onChange={(e, checked) => {
                        const resultValue = checked
                            ? [...(values || []), JSON.parse(e.target.dataset.data as string)]
                            : values.filter((v) => v.id !== value);
                        setValue('select', resultValue);
                    }}
                />
            }
            label={label}
        />
    );
};

type ConnectAccordionProps = {
    values: ConnectedObjectType | ConnectedObjectType[];
    setValue: FormikProps<FormikValues>['setFieldValue'];
    label: string;
    data: EmployeeType[];
};

const ConnectAccordion = ({ values, setValue, label, data }: ConnectAccordionProps) => {
    const isMultiple = Array.isArray(values);
    const allChecked = () =>
        data
            .map((empl) => empl.id)
            .reduce(
                (acc, dataId) =>
                    acc &&
                    Array.isArray(values) &&
                    values.some((empl) => {
                        return empl.id === dataId;
                    }),
                true
            );

    const someChecked = () =>
        data
            .map((empl) => empl.id)
            .reduce(
                (acc, dataId) =>
                    acc ||
                    (Array.isArray(values) &&
                        values.some((empl) => {
                            return empl.id === dataId;
                        })),
                false
            );

    return (
        <Accordion defaultExpanded>
            <Grid container flexWrap={'nowrap'}>
                {isMultiple && (
                    <Checkbox
                        checked={allChecked()}
                        indeterminate={someChecked() && !allChecked()}
                        onChange={(_, checked) => {
                            if (checked) {
                                setValue('select', [
                                    ...values,
                                    ...data.filter((empl) => !values.some((obj) => obj.id === empl.id)),
                                ]);
                            } else {
                                setValue(
                                    'select',
                                    values.filter(
                                        (empl) =>
                                            !data.some((curEmpl) => {
                                                return empl.id === curEmpl.id;
                                            })
                                    )
                                );
                            }
                        }}
                    />
                )}
                <AccordionSummary>{label}</AccordionSummary>
            </Grid>
            <AccordionDetails>
                <Grid container flexDirection={'column'}>
                    {data.map(({ id, user }) => {
                        const { first_name, last_name } = user;
                        return (
                            <>
                                {isMultiple ? (
                                    <ConnectCheckbox
                                        values={values}
                                        value={id}
                                        data={{ id, name: `${first_name} ${last_name}`, user }}
                                        setValue={setValue}
                                        label={`${first_name} ${last_name}`}
                                    />
                                ) : (
                                    <Radio
                                        size={'small'}
                                        key={id}
                                        value={id}
                                        inputProps={{
                                            // @ts-ignore
                                            'data-data': JSON.stringify({
                                                id,
                                                name: `${first_name} ${last_name}`,
                                                user,
                                            }),
                                        }}
                                        label={`${first_name} ${last_name}`}
                                    />
                                )}
                            </>
                        );
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: '0 32px',
}));

export default ConnectEntityModal;
