import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom'; // Для навигации

function useAutoLogout(timeout = 15 * 60 * 1000, setOpenAutoLogout: any) {
    const location = useLocation();
    const handleActivity = useCallback(() => {
        localStorage.setItem('lastActivity', Date.now().toString());
    }, []);

    useEffect(() => {
        if (location.pathname === '/login') {
            return;
        }
        const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];

        events.forEach((event) => window.addEventListener(event, handleActivity));

        if (!localStorage.getItem('lastActivity')) {
            handleActivity(); // Инициализация lastActivity, если отсутствует
        }

        const interval = setInterval(() => {
            const lastActivityString = localStorage.getItem('lastActivity');
            // Если lastActivityString null, используем значение 0 как безопасное значение по умолчанию
            const lastActivity = lastActivityString ? parseInt(lastActivityString, 10) : 0;
            if (Date.now() - lastActivity > timeout) {
                clearInterval(interval);
                setOpenAutoLogout(true);
            }
        }, 1000);

        return () => {
            events.forEach((event) => window.removeEventListener(event, handleActivity));
            clearInterval(interval);
        };
    }, [handleActivity, timeout, location]);

    useEffect(() => {
        if (location.pathname !== 'login') {
            handleActivity();
        } // Обновляем время активности при монтировании
    }, [handleActivity, location]);

    return null;
}

export default useAutoLogout;
