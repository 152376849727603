import { memo } from 'react';
import { Avatar, Divider, Grid, Typography } from '@mui/material';
import { Button, Label } from '../../../../shared/ui';
import { notSpecified } from '../../lib/helpers/notSpecified';
import { genders } from '../../lib/const/genders';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { useUsersStorage } from '../../../../services/StorageAdapter';
import { useTranslation } from 'react-i18next';

export const ProfileInfo = memo(() => {
    const { t } = useTranslation();
    const { user } = useUsersStorage();
    return (
        <Grid
            px={'24px'}
            py={'28px'}
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.16)',
                borderRadius: '12px',
                mb: '24px',
                mt: '10px',
                minWidth: 564,
            }}
        >
            <Grid container flexDirection={'column'} gap={'12px'}>
                <Grid container justifyContent="space-between">
                    <Typography variant={'h6'}>
                        {user.last_name} {user.first_name} {user?.surname}{' '}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ my: '24px' }} />
            <Grid container spacing={3}>
                <Grid item xs={5} sx={{ position: 'relative' }}>
                    <Avatar
                        variant="rounded"
                        alt={user.first_name}
                        src={user?.avatar}
                        sx={{ width: 204, height: 204 }}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Grid mb="24px">
                        <Label label={t('userEmail')} />
                        <Button sx={{ p: 0 }}>{user.email}</Button>
                    </Grid>
                    <Grid mb="24px">
                        <Label label="Телефон" />
                        {user.phone_number ? (
                            <Typography>{user.phone_number}</Typography>
                        ) : (
                            notSpecified('24px')
                        )}
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid>
                            <Label label="Пол" />
                            {user.gender ? (
                                <Typography>
                                    {genders.find((gender: any) => gender.id === user.gender)?.text}
                                </Typography>
                            ) : (
                                notSpecified()
                            )}
                        </Grid>
                        <Grid>
                            <Label label="Статус" />
                            <Typography>
                                <CircleRoundedIcon
                                    color={user.is_active ? 'primary' : 'error'}
                                    sx={{ mr: 1, mb: '-4px' }}
                                    fontSize="small"
                                />
                                {user.is_active ? t('active') : 'Деактивирован'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});
