import { Route } from './types';
import {
    CREATE_ENTITY_ROUTE,
    EDIT_ENTITY_ROUTE,
    INDEX_ROUTE,
    ENTITY_LIST_ROUTE,
    LOGIN_ROUTE,
    RESET_ROUTE,
    FORGOT_ROUTE,
    USERS_AND_ROLES_ROUTE,
    USER_ID_ROUTE,
    ROLE_ID_ROUTE,
    TASKS_ROUTE,
    TRASH,
    CHAT_ROUTE,
    CHAT_LS_ROUTE,
    DUPLICATE_SEARCH_ROUTE,
    REPORT,
    CONSTRUCTOR,
    CONSTRUCTOR_CREATE_ENTITY,
    CONSTRUCTOR_CREATE_FILTER,
    CONSTRUCTOR_EDIT_FILTER,
    CONSTRUCTOR_CREATE_META_FIELD,
    CONSTRUCTOR_CREATE_ENTITY_FIELD,
    CONSTRUCTOR_EDIT_FIELD,
    NOTIFICATION,
    CHAT_SETTINGS,
    DEPARTMENTS_CONTROL,
    CONSTRUCTOR_CREATE_ENTITY_FUNNEL_SETTINGS,
    IMPORT,
    REPORTS,
    REPORT_EDIT,
    REPORT_VIEW,
    TEMP_ROUTE,
    DISTRIBUTION,
    TEMPLATE_DOCUMENTS,
    DISTRIBUTION_DEPARTMENTS,
    DISTRIBUTION_EMPLOYEE,
} from './consts';
import Root from './root';
import { lazy } from 'react';
import { Notifications } from '../../pages/Notifications';
import { Reports } from '../../pages/Reports';
import TemplateDocuments from '../../pages/TemplateDocuments';

//pages
const CreateEntityLazy = lazy(async () => await import('pages/CreateEntity'));
// const TemplateDocumentsLazy = lazy(async () => await import('pages/TemplateDocuments'));
const EntityDetailLazy = lazy(async () => await import('pages/EntityDetail/ui/EntityDetail'));
const EntityListLazy = lazy(async () => await import('pages/EntityList/EntityList'));
const UsersAndRolesLazy = lazy(async () => await import('pages/UsersAndRoles/UsersAndRoles'));
const UserLazy = lazy(async () => await import('pages/UsersAndRoles/Users/User'));
const RoleLazy = lazy(async () => await import('pages/UsersAndRoles/Roles/Role'));
const TrashLazy = lazy(async () => await import('pages/Trash/ui/Trash/Trash'));
const TaskListLazy = lazy(async () => await import('pages/Tasks/List/TaskList'));
const ChatLazy = lazy(async () => await import('pages/Chat/Chat'));
const ReportLazy = lazy(async () => await import('pages/Report/Report'));
const ReportEditLazy = lazy(async () => await import('pages/Report/Report'));
const ReportViewLazy = lazy(async () => await import('pages/ReportView/ReportView'));
const ConstructorLazy = lazy(async () => await import('pages/Constructor/Constructor'));
const DuplicatesLazy = lazy(async () => await import('pages/Duplicates/Duplicates'));
const ConstructorCreateEntityLazy = lazy(
    async () => await import('pages/Constructor/Create/Entity/ConstructorCreateEntity')
);
const ConstructorCreateFilterLazy = lazy(
    async () => await import('pages/Constructor/Create/Filter/ConstructorCreateFilter')
);
const ConstructorCreateFieldLazy = lazy(
    async () => await import('pages/Constructor/Create/Field/ConstructorCreateField')
);
const ImportLazy = lazy(async () => await import('pages/Import/ui/Import'));
const ChatSettingsLazy = lazy(async () => await import('pages/ChatSettings/ui/ChatSettings/ChatSettings'));
const DepartmentsControlLazy = lazy(async () => await import('pages/DepartmentsControl/DepartmentsControl'));
const FunnelSettingsLazy = lazy(
    async () => await import('pages/FunnelSettings/ui/FunnelSettingsPage/FunnelSettingsPage')
);
const LoginLazy = lazy(async () => await import('pages/Auth/ui/Login/Login'));
const ForgotLazy = lazy(async () => await import('pages/Auth/ui/Forgot/Forgot'));
const ResetLazy = lazy(async () => await import('pages/Auth/ui/Reset/Reset'));
const TempPage = lazy(async () => await import('pages/TempPage'));
const DistributionLazy = lazy(async () => await import('pages/Distribution'));
const DistributionDepartmentsLazy = lazy(async () => await import('pages/DistributionDepartments'));
const protectedRoutes = [INDEX_ROUTE, CREATE_ENTITY_ROUTE, ENTITY_LIST_ROUTE, USERS_AND_ROLES_ROUTE];

const routes: Route[] = [
    {
        path: TEMPLATE_DOCUMENTS,
        component: TemplateDocuments,
    },
    {
        path: DISTRIBUTION,
        component: DistributionLazy,
    },
    {
        path: DISTRIBUTION_EMPLOYEE,
        component: DistributionLazy,
    },
    {
        path: DISTRIBUTION_DEPARTMENTS,
        component: DistributionDepartmentsLazy,
    },
    {
        path: CREATE_ENTITY_ROUTE,
        component: CreateEntityLazy,
    },
    {
        path: TEMP_ROUTE,
        component: TempPage,
    },
    {
        path: EDIT_ENTITY_ROUTE,
        component: EntityDetailLazy,
    },
    {
        path: ENTITY_LIST_ROUTE,
        component: EntityListLazy,
    },
    {
        path: USERS_AND_ROLES_ROUTE,
        component: UsersAndRolesLazy,
    },
    {
        path: USER_ID_ROUTE,
        component: UserLazy,
    },
    {
        path: ROLE_ID_ROUTE,
        component: RoleLazy,
    },
    {
        path: TASKS_ROUTE,
        component: TaskListLazy,
    },
    {
        path: TRASH,
        component: TrashLazy,
    },
    {
        path: CHAT_ROUTE,
        component: ChatLazy,
    },
    {
        path: CHAT_LS_ROUTE,
        component: ChatLazy,
    },
    {
        path: DUPLICATE_SEARCH_ROUTE,
        component: DuplicatesLazy,
    },
    {
        path: REPORT,
        component: ReportLazy,
    },
    {
        path: REPORT_EDIT,
        component: ReportEditLazy,
    },
    {
        path: CONSTRUCTOR,
        component: ConstructorLazy,
    },
    {
        path: CONSTRUCTOR_CREATE_ENTITY,
        component: ConstructorCreateEntityLazy,
    },
    {
        path: CONSTRUCTOR_CREATE_FILTER,
        component: ConstructorCreateFilterLazy,
    },
    {
        path: CONSTRUCTOR_EDIT_FILTER,
        component: ConstructorCreateFilterLazy,
    },
    {
        path: CONSTRUCTOR_CREATE_META_FIELD,
        component: ConstructorCreateFieldLazy,
    },
    {
        path: CONSTRUCTOR_CREATE_ENTITY_FIELD,
        component: ConstructorCreateFieldLazy,
    },
    {
        path: CONSTRUCTOR_EDIT_FIELD,
        component: ConstructorCreateFieldLazy,
    },
    {
        path: NOTIFICATION,
        component: Notifications,
    },
    {
        path: CHAT_SETTINGS,
        component: ChatSettingsLazy,
    },
    {
        path: DEPARTMENTS_CONTROL,
        component: DepartmentsControlLazy,
    },
    {
        path: CONSTRUCTOR_CREATE_ENTITY_FUNNEL_SETTINGS,
        component: FunnelSettingsLazy,
    },
    {
        path: IMPORT,
        component: ImportLazy,
    },
    {
        path: REPORTS,
        component: Reports,
    },
    {
        path: REPORT_VIEW,
        component: ReportViewLazy,
    },
];

const authRoutes = [
    {
        path: LOGIN_ROUTE,
        component: LoginLazy,
    },
    {
        path: RESET_ROUTE,
        component: ResetLazy,
    },
    {
        path: FORGOT_ROUTE,
        component: ForgotLazy,
    },
];

export { Root, protectedRoutes, routes, authRoutes };
