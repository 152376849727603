import { Divider } from '@mui/material';
import React, { useState } from 'react';
import TemplateHeader from './ui/TemplateHeader';
import HeaderTab from './ui/HeaderTab';
import TemplateSearch from './ui/TemplateSearch';
import { TabPanel } from '../../shared/ui';
import { buttonsLabel } from './consts';
import DocumentTypes from './ui/DocumentTypes';
import DocumentTemplates from './ui/DocumentTemplates';

const TemplateDocuments = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [addTypeModal, setAddTypeModal] = useState(false);
    const [addGeneratedModal, setAddGeneratedModal] = useState(false);

    const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    const toggleModal = () =>
        currentTab === 0 ? setAddTypeModal(!addTypeModal) : setAddGeneratedModal(!addGeneratedModal);
    return (
        <>
            <TemplateHeader />
            <Divider />
            <HeaderTab currentTab={currentTab} handleChangeTab={handleChangeTab} />
            <TemplateSearch btnLabel={buttonsLabel[currentTab]} openModal={toggleModal} />
            <TabPanel index={0} value={currentTab}>
                <DocumentTypes toggleModal={toggleModal} open={addTypeModal} />
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
                <DocumentTemplates toggleModal={toggleModal} open={addGeneratedModal} />
            </TabPanel>
        </>
    );
};

export default TemplateDocuments;
