import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Button, Label, Upload } from '../../../../../shared/ui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Axios from '../../../../../shared/api/axios';
import { useNotification } from '../../../../../services/StorageAdapter';

const UploadComponent = ({
    documentId,
    onClose,
    setSuccess,
}: {
    documentId: string | null;
    onClose: () => void;
    setSuccess: any;
}) => {
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState(false);
    const { notify } = useNotification();

    const handleClick = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file_path', file);
        Axios.patch(`/api/v1/documents/document-templates/${documentId}`, formData)
            .then(() => {
                notify({ message: 'Успешно создан' });
                onClose();
                setSuccess((prev: boolean) => !prev);
            })
            .finally(() => setLoading(false));
    };
    return (
        <Grid sx={{ padding: '20px 30px' }}>
            <Typography variant="body1" mb={3}>
                Загрузите шаблон документа, который вы создали с помощью ID на предыдущем шаге (Настройка).
            </Typography>
            <Grid item>
                <Label label="Файл" />
                <Upload
                    withoutPreloader={true}
                    format=".DOCX"
                    accept={{
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                            '.docx',
                            '.doc',
                        ],
                    }}
                    uploadedFiles={(files: any) => {
                        setFile(files[0].file);
                    }}
                />
            </Grid>

            <Grid container justifyContent="flex-end" mt={3}>
                <Button variant="outlined" sx={{ marginRight: '20px' }} startIcon={<ArrowBackIcon />}>
                    Назад
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!file}
                    onClick={handleClick}
                    loading={loading}
                >
                    Создать шаблон
                </Button>
            </Grid>
        </Grid>
    );
};

export default UploadComponent;
