import { createEffect, createEvent, createStore, sample } from 'effector';
import { EntityData, TFunnelStageModal } from 'domain/entity';
import { dataApiService, fieldApiService } from 'services/ApiAdapter';
import { Field } from 'domain/field';
import { entityService } from 'shared/api/entity';
import { fieldsService } from 'shared/api/fields.service';
import { funnelsService } from 'shared/api/funnels.service';
import { states, TFunnels } from 'domain/funnels';
import { ConnectedObjectType } from 'pages/Tasks/List/Card/TaskListCard';
import { Nullable } from 'shared/types';
import { IDataApiService } from '../../../application/ports/Api';

const fetchEntityDataFx = createEffect(async ({ code, id }: { code: string; id: string }) => {
    const dataApi = dataApiService();

    return await dataApi.getEntityData(code, id);
});

const fetchEntityDataUpdaterFx = createEffect(async ({ code, id }: { code: string; id: string }) => {
    const dataApi = dataApiService();

    return await dataApi.getEntityData(code, id);
});

const updateEntityFieldFx = createEffect(async ({ field }: { field: Partial<Field> }) => {
    const fieldApi = fieldApiService();

    return await fieldApi.updateField(field);
});

const updateEntityFieldsFx = createEffect(async ({ fields }: { fields: Partial<Field>[] }) => {
    const fieldApi = fieldApiService();
    return await fieldApi.bulkUpdateFields(fields);
});

const updateEntityFieldGroupFx = createEffect(
    async ({ field, groupId }: { field: Field; groupId: string }) => {
        const fieldApi = fieldApiService();

        await fieldApi.updateFieldGroup(field, groupId);
    }
);

const updateEntityTitleFx = createEffect(
    async ({ code, entityId, title }: { code: string; entityId: string; title: string }) => {
        return await entityService.updateEntity(code, entityId, { title });
    }
);

const createEntityFx = createEffect(async ({ code, entity }: { code: string; entity: EntityData }) => {
    return await entityService.createEntity(code, entity);
});

const deleteEntityFx = createEffect(async ({ code, id }: { code: string; id: string }) => {
    return await entityService.deleteEntity(code, id);
});

const updateEntityDataFx = createEffect(
    async ({ code, entity }: { code: string; entity: Partial<EntityData> }): Promise<EntityData> => {
        const { id, ...otherEntity } = entity;
        return await entityService.updateEntity(code, id!, otherEntity);
    }
);

const bulkUpdateEntitesFx = createEffect(
    async ({ code, entity }: { code: string; entity: EntityData[] }): Promise<EntityData> => {
        return await entityService.bulkUpdateEntites(code, entity);
    }
);

const updateFieldsGroupFx = createEffect(
    async ({ groupCode, params }: { groupCode: string; params: any }) => {
        return await fieldsService.updateFieldsGroup(groupCode, params);
    }
);

const changeEntityStageFx = createEffect(
    async ({ stage, entityId }: { stage: { id: string; name: string }; entityId: string }) => {
        await funnelsService.setFunnelsStagesID(stage.id, {
            entity_objects: [entityId],
        });
        return stage;
    }
);

const changeGroupsOrderFx = createEffect(async (data: { id: string; [k: string]: any }[]) => {
    return fieldsService.bulkUpdateGroups(data);
});

// sample({
//     clock: changeGroupsOrderFx.doneData,
//     source: $metaStore,
//     target: fetchMetaFx,
//     fn: (sourceStore) => ({ meta: sourceStore.entityName, params: { funnel_view_type: 'detail' } }),
// });

export type ConnectEntityModalSettingsType = {
    type: string;
    code: string;
    isOpen: boolean;
    selected: Nullable<ConnectedObjectType | ConnectedObjectType[]>;
    handleSelect: (entity: ConnectedObjectType | ConnectedObjectType[]) => void;
    modalTitle?: string;
};

const initialConnectEntityModalSettings: ConnectEntityModalSettingsType = {
    type: '',
    code: '',
    modalTitle: '',
    handleSelect: () => {},
    selected: [],
    isOpen: false,
};

const openConnectEntityModal = createEvent<Omit<ConnectEntityModalSettingsType, 'isOpen'>>();
const closeConnectEntityModal = createEvent();

const $connectEntityModalStore = createStore(initialConnectEntityModalSettings)
    .on(openConnectEntityModal, (_, data) => ({
        ...data,
        isOpen: true,
    }))
    .on(closeConnectEntityModal, (state) => ({ ...state, isOpen: false, modalTitle: '' }));

const fetchEntityList = createEffect(
    async ({
        code,
        limit = 100,
        offset,
        search,
        is_deleted = false,
        entity_is_deleted = false,
    }: {
        code: string;
        limit?: number;
        offset?: number;
        search?: string;
        is_deleted?: boolean;
        entity_is_deleted?: boolean;
    }) => {
        return await entityService.getEntities(code, {
            limit,
            offset,
            search,
            is_deleted,
            entity_is_deleted,
        });
    }
);

const searchEntityFx = createEffect(
    async ({
        code,
        text,
        entity_is_deleted,
        is_deleted,
        limit,
        offset,
    }: {
        code: string;
        text: string;
        entity_is_deleted: boolean;
        is_deleted: boolean;
        limit: number;
        offset: number;
    }) => {
        return await entityService.getEntities(code, {
            search: text,
            entity_is_deleted,
            is_deleted,
            limit,
            offset,
        });
    }
);

// sample({
//     clock: openConnectEntityModal,
//     source: $connectEntityModalStore,
//     fn: ({ code }) => ({ code }),
//     target: fetchEntityList,
// });

const fetch = createEvent<{ limit: number; offset: number; search?: string }>();
const fetchAdd = createEvent<{ count: number; code: string }>();
const updateFunnelStage = createEvent<any>();
const updateBulkFunnelStage = createEvent<any>();
const resetParams = createEvent();

const $entityListStore = createStore<Array<{ id: string; title: string }>>([])
    .on(fetchEntityList.doneData, (state, data) => [...state, ...data.results])
    .on(searchEntityFx.doneData, (_, data) => data.results)
    .reset(resetParams);

export type FetchParams = { limit: number; count: number; offset: number; hasNext: boolean; search?: string };
const $entityParams = createStore<FetchParams>({
    limit: 20,
    count: 0,
    offset: 0,
    hasNext: true,
})
    .on(fetchEntityList.doneData, (state, data) => ({
        count: data.count,
        hasNext: !!data.next,
        limit: 20,
        offset: state.offset + 20,
        search: state.search,
    }))
    .on(searchEntityFx.doneData, (state, data) => ({
        count: data.count,
        hasNext: !!data.next,
        limit: 20,
        offset: state.offset + 20,
        search: state.search,
    }))
    .reset(resetParams);

sample({
    clock: fetch,
    source: $connectEntityModalStore,
    target: fetchEntityList,
    fn: (sourceData, clockData) => ({
        code: sourceData.code,
        limit: clockData.limit,
        offset: clockData.offset,
        search: clockData.search,
    }),
});

const initialFunnelData: TFunnels = {
    id: '',
    name: '',
    entity: '',
    type: '',
    archived: false,
    stages: [],
};
// const initialRelationsSettingsData = {
//     id: '',
//     code: '',
//     name: '',
//     type: '',
//     entity_fields: [],
// };
const initialModal: TFunnelStageModal = {
    isOpen: false,
    data: {},
};

const updateEntityFunnelStageModal = createEvent<TFunnelStageModal>();
const clearEntityFunnelStageModal = createEvent();

const $entityFunnelStageModalStore = createStore(initialModal)
    .on(updateEntityFunnelStageModal, (_, data) => data)
    .reset(clearEntityFunnelStageModal);

const fetchEntityFunnelStageFx = createEffect(async (funnel_id: string) => {
    return await funnelsService.getFunnelsID(funnel_id);
});

const updateEntityFunnelStageFx = createEffect(async (data: any) => {
    return await funnelsService.updateFunnel(data);
});

const fetchAllRelationsFx = createEffect(
    async ({ code, objectId, params }: { code: string; objectId: string; params?: any }) => {
        return await entityService.getAllRelations(code, objectId, params);
    }
);
const fetchRelationsFx = createEffect(async (code: string) => {
    return await entityService.getRelations(code);
});

const fetchRelatedObjectsFx = createEffect(async ({ code, objectId }: { code: string; objectId: string }) => {
    return await entityService.getRelatedObjects(code, objectId);
});

const fetchDataContractsFx = createEffect(async (prop: { code: string; id: string }) => {
    const dataApi: IDataApiService = dataApiService();

    return await dataApi.getDataCodeID(
        prop.code,
        {},
        {
            '9596eeb8-07ad-4fc3-9291-13340ab8b41d__icontains': prop.id,
        }
    );
});
const saveEntitySettingsFx = createEffect(
    async ({ code, id, values }: { code: string; id: string; values: any }) => {
        const dataApi: IDataApiService = dataApiService();
        return dataApi.updateEntitySettings({ code, id, values });
    }
);
const $dataContractsStore = createStore({}).on(fetchDataContractsFx.doneData, (_, data) => {
    return data.results;
});
const $relationsSettingsStore = createStore({}).on(fetchRelationsFx.doneData, (_, data) => {
    return data;
});

const $relatedObjectsStore = createStore({}).on(fetchRelatedObjectsFx.doneData, (_, data) => data);

const $relationsStore = createStore([]).on(fetchAllRelationsFx.doneData, (_, data: any) => {
    return data.relations;
});

const $entityFunnelStore = createStore<TFunnels>(initialFunnelData)
    .on(fetchEntityFunnelStageFx.doneData, (state: any, funnel: any) => {
        const newStages = states.map((state: any) => {
            return {
                state,
                arr: funnel.stages.filter((stage: any) => stage.state === state.type),
            };
        });
        return {
            ...state,
            ...funnel,
            stages: newStages,
        };
    })
    .on(updateEntityFunnelStageFx, (state: any, payload: any) => {
        return {
            ...state,
            ...payload,
        };
    })
    .on(updateFunnelStage, (state: any, payload: any) => {
        return {
            ...state,
            ...payload,
        };
    })
    .on(updateBulkFunnelStage, (state: any, payload) => {
        return {
            ...state,
            stages: state.stages.map((stage: any) => {
                if (stage.state.type === 'in_progress') {
                    return {
                        ...stage,
                        arr: payload,
                    };
                }
                return stage;
            }),
        };
    });

export const entityDetailEvents = {
    openConnectEntityModal,
    closeConnectEntityModal,
    fetch,
    fetchAdd,
    updateFunnelStage,
    updateBulkFunnelStage,
    resetParams,
    updateEntityFunnelStageModal,
};

export const entityDetailStores = {
    $entityFunnelStageModalStore,
    $connectEntityModalStore,
    $entityListStore,
    $entityFunnelStore,
    $entityParams,
    $relationsStore,
    $relationsSettingsStore,
    $relatedObjectsStore,
    $dataContractsStore,
};

export const entityDetailEffects = {
    fetchEntityDataUpdaterFx,
    fetchEntityDataFx,
    updateEntityTitleFx,
    updateEntityFieldGroupFx,
    updateEntityFieldFx,
    updateEntityDataFx,
    updateEntityFieldsFx,
    createEntityFx,
    deleteEntityFx,
    updateFieldsGroupFx,
    fetchEntityFunnelStageFx,
    changeEntityStageFx,
    changeGroupsOrderFx,
    searchEntityFx,
    bulkUpdateEntitesFx,
    fetchAllRelationsFx,
    fetchRelationsFx,
    updateEntityFunnelStageFx,
    fetchRelatedObjectsFx,
    fetchDataContractsFx,
    saveEntitySettingsFx,
};
