import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { Button, Grid, Pagination, SelectChangeEvent } from '@mui/material';
import { Select } from '..';
import TableBottom from './TableBottom';
import component from 'widgets/Duplicates/Duplicates.module.scss';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import classNames from 'classnames';
import { useRef } from 'react';
import { useHorizontalScroll } from 'shared/hooks/useHorizontalScroll';
import { TParamsRequest } from 'shared/api/types';
import { ITableDropdown } from './TableDropdown';
import { DragDropContext } from '@hello-pangea/dnd';
import EnhancedTableHead from './EnhancedTableHeader';
import EnhancedTableRow from './EnhancedTableRow';
import { useTranslation } from 'react-i18next';

type Order = 'asc' | 'desc';

interface ITableProps {
    onDoubleClick?: (value?: string) => void;
    onUpdate: (params: TParamsRequest) => void;
    onSelect?: ((selected: string[]) => void) | undefined;
    system_header?: any[];
    header: any[];
    data: any[];
    limit: number;
    offset: number;
    count: number;
    border?: boolean;
    clear?: number;
    show_bottom?: boolean;
    show_selected?: boolean;
    oneClick?: (value?: string) => void;

    menu?: ITableDropdown[];
    bottom_menu?: ITableDropdown[];
    settings?: ITableDropdown[];

    onSort?: ((field_id: string) => void) | undefined;
    order?: Order;
    orderBy?: string;

    onDragEnd?: (result?: any) => void;
    dnd?: boolean;
    dndHead?: boolean;
    droppableId?: string;
    changeHeaders?: (result: any[]) => void;
    withoutMargin?: boolean;
    overflowX?: string;
    openTaskModal?: any;
}

export default function EnhancedTable({
    onDoubleClick,
    onSelect,
    onUpdate,
    data,
    limit,
    oneClick,
    offset,
    count,
    header,
    clear,
    system_header,
    border = true,
    show_bottom = true,
    show_selected = true,
    openTaskModal,
    menu,
    bottom_menu,
    settings,

    onSort,
    order,
    orderBy,

    onDragEnd,
    dnd = false,
    dndHead = false,
    droppableId,
    changeHeaders,
    overflowX = 'scroll',
    withoutMargin,
}: ITableProps) {
    const { t } = useTranslation();
    const bodyRef = useRef<HTMLTableSectionElement>(null);
    const { showLeftScroll, showRightScroll, scrollStop, scrollStart } = useHorizontalScroll(bodyRef);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [edit, setEdit] = React.useState<boolean>(false);

    React.useEffect(() => onSelect?.(selected), [selected]);
    React.useEffect(() => setSelected([]), [clear]);

    const handleRequestSort = (property: any) => {
        onSort?.(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.checked) {
            setSelected(data.map((n: any) => n.id));
            return;
        }
        setSelected([]);
    };

    const handleClearAllClick = () => {
        setSelected([]);
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        onUpdate({ offset: (newPage - 1) * limit, limit });
    };

    const handleChangeRowsPerPage = (event: SelectChangeEvent<unknown>) => {
        const num = typeof event?.target?.value === 'number' ? event?.target?.value : 0;
        onUpdate({ offset: 0, limit: num });
    };

    const handleClick = (name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };
    return (
        <Box
            sx={{
                width: '100%',
                mb: withoutMargin ? 0 : 2,
                position: 'relative',
                borderRadius: '12px !important',
                border: border ? '1px solid #00000029' : 0,
                boxShadow: 'none',
            }}
            mt={withoutMargin ? 0 : 2}
        >
            <div
                onMouseDown={() => scrollStart('right')}
                onMouseUp={scrollStop}
                className={component['scroll-button']}
                style={{
                    display: showRightScroll ? 'flex' : 'none',
                }}
            >
                <ChevronRightRounded sx={{ color: 'white' }} fontSize={'medium'} />
            </div>
            <div
                onMouseDown={() => scrollStart('left')}
                onMouseUp={scrollStop}
                className={classNames(component['scroll-button'], component['scroll-button--left'])}
                style={{
                    display: showLeftScroll ? 'flex' : 'none',
                }}
            >
                <ChevronLeftRounded sx={{ color: 'white' }} fontSize={'medium'} />
            </div>
            <TableContainer ref={bodyRef} sx={{ overflowX }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        numSelected={selected?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onClearAllClick={handleClearAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data?.length}
                        edit={edit}
                        header={header}
                        system_header={system_header}
                        settings={settings}
                        show_selected={show_selected}
                        dnd={dnd}
                        dndHead={dndHead}
                        changeHeaders={changeHeaders}
                    />
                    {!dnd ? (
                        <DragDropContext onDragEnd={(r) => onDragEnd?.(r)}>
                            <EnhancedTableRow
                                data={data}
                                header={[...(system_header || []), ...header]}
                                onCheck={handleClick}
                                onEdit={setEdit}
                                selected={selected}
                                dnd={dnd}
                                droppableId={droppableId}
                                edit={edit}
                                menu={menu}
                                openTaskModal={openTaskModal}
                                onDoubleClick={onDoubleClick}
                                oneClick={oneClick}
                                onDragEnd={onDragEnd}
                                show_selected={show_selected}
                            />
                        </DragDropContext>
                    ) : (
                        <EnhancedTableRow
                            data={data}
                            header={[...(system_header || []), ...header]}
                            onCheck={handleClick}
                            onEdit={setEdit}
                            selected={selected}
                            dnd={dnd}
                            droppableId={droppableId}
                            openTaskModal={openTaskModal}
                            edit={edit}
                            menu={menu}
                            oneClick={oneClick}
                            onDoubleClick={onDoubleClick}
                            onDragEnd={onDragEnd}
                            show_selected={show_selected}
                        />
                    )}
                </Table>
            </TableContainer>
            {show_bottom &&
                (!selected?.length ? (
                    <Grid
                        container
                        gap={'12px'}
                        py={'19px'}
                        px={3}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        fontSize={12}
                    >
                        {count > limit && (
                            <>
                                <Grid>
                                    <Pagination
                                        count={Math.ceil(count / limit)}
                                        page={offset / limit + 1}
                                        onChange={handleChangePage}
                                        shape="rounded"
                                        color="primary"
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid
                            item
                            gap={'12px'}
                            display="flex"
                            alignItems={'center'}
                            marginLeft={'auto'}
                            width={'auto'}
                        >
                            <Typography variant={'body1'} sx={{ whiteSpace: 'nowrap' }}>
                                {t('linePerPage')}
                            </Typography>
                            <Select
                                items={[
                                    {
                                        id: 5,
                                        text: '5',
                                    },
                                    {
                                        id: 10,
                                        text: '10',
                                    },
                                    {
                                        id: 15,
                                        text: '15',
                                    },
                                    {
                                        id: 20,
                                        text: '20',
                                    },
                                ]}
                                value={limit}
                                onChange={handleChangeRowsPerPage}
                                sx={{
                                    fontSize: 14,
                                    width: 'auto',
                                }}
                            />
                        </Grid>
                    </Grid>
                ) : edit ? (
                    <Grid
                        container
                        gap={'16px'}
                        py={'19px'}
                        px={3}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Grid container gap={'16px'} alignItems={'center'} width={'auto'}>
                            <Grid
                                container
                                gap={'12px'}
                                alignItems={'center'}
                                width={'auto'}
                                borderRight={'1px solid rgba(0, 0, 0, 0.12)'}
                                pr={3}
                            >
                                <Typography variant={'h6'} fontSize={14}>
                                    {t('editingCount', { count: selected?.length })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container gap={'12px'} alignItems={'center'} width={'auto'}>
                            <Button color="primary" variant="contained" onClick={() => setEdit(false)}>
                                {t('saveAllChanges')}
                            </Button>
                            <Button color="primary" variant="outlined" onClick={() => setEdit(false)}>
                                {t('cancelAllChanges')}
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <TableBottom
                        count={selected?.length}
                        selected={selected}
                        onEdit={() => setEdit(true)}
                        onClear={() => setSelected([])}
                        buttons={bottom_menu}
                    />
                ))}
        </Box>
    );
}
