import {
    ICreateDocumentBody,
    IDocumentTemplate,
    IDocumentTemplatesList,
    IDocumentType,
    IDocumentTypeList,
} from '../../../domain/documents';
import { createEffect, createStore } from 'effector';
import { documentsApiService, funnelsApiService } from '../../ApiAdapter';
import { IFunnelsApiService } from '../../../application/ports/Api';
import { TParamsRequest } from '../../../shared/api/types';

const initialValue: IDocumentTypeList = {
    results: [],
    count: 0,
    limit: 10,
    params: {
        limit: 10,
        offset: 0,
    },
};

const initialValuTemplates: IDocumentTemplatesList = {
    results: [],
    count: 0,
    limit: 10,
    offset: 0,
};

const fetchFunnelListFx = createEffect(async () => {
    const funnelsApi: IFunnelsApiService = funnelsApiService();
    return funnelsApi.getFunnels({ limit: 100 });
});

const fetchDocumentsFx = createEffect(async (params: TParamsRequest) => {
    const documentsApi = documentsApiService();
    return documentsApi.getDocuments(params);
});

const fetchTemplateDocumentsFx = createEffect(async (params: TParamsRequest) => {
    const documentsApi = documentsApiService();
    return documentsApi.getTemplateDocuments(params);
});

const createDocumentTypeFx = createEffect(async (body: ICreateDocumentBody) => {
    const documentsApi = documentsApiService();
    return documentsApi.createDocument(body);
});

const updateDocumentTypeFx = createEffect(async (body: { name: string; id: string }) => {
    const documentsApi = documentsApiService();
    return documentsApi.updateTypeDocument(body);
});

const fetchNumeratorsFx = createEffect(async () => {
    const documentsApi = documentsApiService();
    return documentsApi.getNumerators();
});

const $documentTemplatesStore = createStore<IDocumentTemplatesList>(initialValuTemplates).on(
    fetchTemplateDocumentsFx.doneData,
    (_, newData) => {
        return {
            ...newData,
            results: newData.results.map((item: IDocumentTemplate) => ({
                ...item,
                name: item.name,
                entityAccess: item.funnel_ids.map((i) => i.name).join(', '),
                funnel_stage: (item.funnel_stage_ids || []).map((i) => i.name).join(', '),
                file: item.document_type.name,
                roleAccess: item.roles_access.map((i) => i.role.name),
                numerator: item.numerator.name,
            })),
        };
    }
);

const $documentsStore = createStore<IDocumentTypeList>(initialValue)
    .on(fetchDocumentsFx.doneData, (_, newData) => {
        return {
            ...newData,
            results: newData.results.map((i: any) => ({
                ...i,
                metaName: i.entities.map((i: any) => i.name).join(','),
                status: i.is_active ? '' : 'Аннулирован',
            })),
        };
    })
    .on(createDocumentTypeFx.doneData, (state, newDocument: IDocumentType) => {
        return {
            ...state,
            results: [
                ...state.results,
                {
                    ...newDocument,
                    metaName: newDocument.entities.map((i) => i.name).join(','),
                    status: newDocument.is_active ? '' : 'Аннулирован',
                },
            ],
        };
    })
    .on(updateDocumentTypeFx.doneData, (state, newDocument) => {
        return {
            ...state,
            results: state.results.map((d) =>
                d.id === newDocument.id ? { ...d, status: newDocument.is_active ? '' : 'Аннулирован' } : d
            ),
        };
    });

export const documentsStore = {
    $documentsStore,
    $documentTemplatesStore,
};
export const documentEffects = {
    fetchDocumentsFx,
    createDocumentTypeFx,
    updateDocumentTypeFx,
    fetchFunnelListFx,
    fetchNumeratorsFx,
    fetchTemplateDocumentsFx,
};
