import React, { useEffect, useMemo } from 'react';
import { Button, Chip, Input, Label, Modal } from 'shared/ui';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import Axios from 'shared/api/axios';
import { useNotification, useUsersStorage } from '../../../../services/StorageAdapter';
import { OptionType } from '../../../../shared/types';
import { INumerator } from './AddGenerateDocumentModal';

interface IProps {
    open: boolean;
    onClose: () => void;
    handleAddNumerator: (arg: OptionType) => void;
    numeratorId: string;
    isEdit: boolean;
}
interface IValues {
    name: string;
    current_number: number | null;
    number_template: null | string;
}
const NumeratorModal = (props: IProps) => {
    const { open, onClose, handleAddNumerator, isEdit, numeratorId } = props;
    const { notify } = useNotification();
    const { me } = useUsersStorage();

    const { values, handleSubmit, setFieldValue, setValues } = useFormik<any>({
        initialValues: {
            name: '',
            current_number: null,
            number_template: null,
        },
        onSubmit: (values) => {
            const newValues = { ...values, current_number: Number(values.current_number) };
            Axios.post<IValues, INumerator>('/api/v1/documents/numerators', newValues).then((result) => {
                handleAddNumerator({ text: result.name, id: result.id });
                notify({
                    message: 'Успешно создан',
                });
                onClose();
            });
        },
    });

    const handleClick = () => {
        Axios.post('/api/v1/documents/numerators/reserve', { user_id: me.id }).catch((e) =>
            notify({
                message: e.response.data.error,
            })
        );
    };

    useEffect(() => {
        if (isEdit) {
            Axios.get(`/api/v1/documents/numerators/${numeratorId}`).then((result) => {
                setValues(result);
            });
        }
    }, [isEdit]);

    const isDisabled = useMemo(() => {
        return Object.values(values).every((i) => Boolean(i));
    }, [values]);

    return (
        <Modal title="Нумератор" width={750} open={open} onClose={onClose}>
            <form onSubmit={handleSubmit}>
                <Grid container sx={{ padding: '30px' }} spacing={3}>
                    <Grid item md={12}>
                        <Label isRequired={true} label="Название нумератора" />
                        <Input
                            fullWidth
                            value={values['name']}
                            onChange={(e) => setFieldValue('name', e.target.value)}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Label label="Шаблон номера" />
                        <Input
                            fullWidth
                            type="number"
                            value={values['number_template']}
                            onChange={(e) => setFieldValue('number_template', e.target.value)}
                        />
                        <Chip
                            sx={{ marginTop: '10px', cursor: 'pointer' }}
                            onClick={() => handleClick()}
                            label="Последовательный номер"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Label label="Текущий номер" />
                        <Input
                            fullWidth
                            value={values['current_number']}
                            type="number"
                            onChange={(e) => setFieldValue('current_number', e.target.value)}
                        />
                    </Grid>
                    {/*<Grid item md={12}>*/}
                    {/*    <Label label="Активен?" />*/}
                    {/*    <Select fullWidth items={[{ id: 'xd', text: 'Не выбрано' }]} />*/}
                    {/*</Grid>*/}
                </Grid>
                <Grid container pr={3} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{ marginRight: '20px' }}
                        disabled={!isDisabled}
                    >
                        Сохранить
                    </Button>
                    <Button variant="outlined">Отменить</Button>
                </Grid>
            </form>
        </Modal>
    );
};

export default NumeratorModal;
